import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import ProfileCircle from '../ProfileCircle';
import ColorToggleButton from '../ColorToggleButton';

const ZoomControls = ({
  zoomLevel,
  onZoomIn,
  onZoomOut,
  onResetZoom,
  isDraggingEnabled,
  onToggleDragging,
  profileData,
}) => {
  const location = useLocation();
  const [isZooming, setIsZooming] = useState(false);

  // Determine if the current path should hide zoom and drag controls
  const isLandingOrUploadPage =
    location.pathname === '/home' ||
    location.pathname === '/upload' ||
    location.pathname === '/profile';

  // New handler to reset zoom and drag position
  const handleResetZoomAndPosition = () => {
    console.log('Resetting zoom and position');
    onResetZoom(); // Call the provided prop function for reset
  };

  // Handler to detect middle mouse click
  const handleMiddleClick = (event) => {
    if (event.button === 1) { // 1 represents middle mouse button
      event.preventDefault(); // Prevent default behavior of middle click (like auto-scroll)
      handleResetZoomAndPosition();
    }
  };

  // New handler for wheel events
  const handleWheel = useCallback((e) => {
    if (isZooming) {
      e.preventDefault();
      if (e.deltaY < 0) {
        onZoomIn();
      } else {
        onZoomOut();
      }
    }
  }, [isZooming, onZoomIn, onZoomOut]);

  // New handlers for keydown and keyup events
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'z') {
      setIsZooming(true);
    }
  }, []);

  const handleKeyUp = useCallback((e) => {
    if (e.key === 'z') {
      setIsZooming(false);
    }
  }, []);

  // Effect to add and remove event listeners
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('wheel', handleWheel);
    };
  }, [handleKeyDown, handleKeyUp, handleWheel]);

  return (
    <div
      className="fixed bottom-4 left-4 flex items-center gap-2 z-50"
      onMouseDown={handleMiddleClick} // Add middle click event listener to the container
    >
      {/* Always show the ProfileCircle with updated profileData */}
      <div className="hover:opacity-80">
        <ProfileCircle profileData={profileData} />
      </div>
      {/* Conditionally render Zoom and Drag controls */}
      {!isLandingOrUploadPage && (
        <>
          {/* Zoom Controls */}
          <div
            className="ml-1 h-8 px-3 bg-sidebarBackground rounded-lg border border-borderColor flex justify-center items-center gap-1"
            style={{ minWidth: '100px' }}
          >
            {/* Zoom Out Button */}
            <button
              aria-label="Zoom Out"
              className={`w-4 h-4 flex justify-center items-center text-center text-sm font-semibold text-textLight ${zoomLevel <= 50 ? 'opacity-25 cursor-not-allowed' : 'opacity-50'
                } hover:opacity-100 transition-opacity duration-200`}
              onClick={onZoomOut}
              disabled={zoomLevel <= 50}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                viewBox="0 0 12 12"
                className="hover:stroke-[#ffffff] transition-colors duration-200"
              >
                <path
                  stroke="var(--white)"
                  strokeWidth="1.5"
                  d="M10.5 6h-9"
                />
              </svg>
            </button>

            {/* Zoom Level Display - Clickable to reset zoom and drag position */}
            <button
              className="text-textLight text-xs font-mono leading-none opacity-50 hover:opacity-100 transition-opacity duration-200 cursor-pointer"
              style={{ width: '40px' }}
              onClick={handleResetZoomAndPosition} // Attach the reset handler here
              aria-label="Reset Zoom and Position"
            >
              {zoomLevel}%
            </button>

            {/* Zoom In Button */}
            <button
              aria-label="Zoom In"
              className={`w-4 h-4 flex justify-center items-center text-center text-sm font-semibold text-textLight ${zoomLevel >= 300 ? 'opacity-25 cursor-not-allowed' : 'opacity-50'
                } hover:opacity-100 transition-opacity duration-200`}
              onClick={onZoomIn}
              disabled={zoomLevel >= 300}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                viewBox="0 0 12 12"
                className="hover:stroke-[#ffffff] transition-colors duration-200"
              >
                <path
                  stroke="var(--white)"
                  strokeWidth="1.5"
                  d="M6 1.5V6m0 4.5V6m0 0h4.5M6 6H1.5"
                />
              </svg>
            </button>
          </div>

          {/* Drag Toggle Button */}
          <button
            aria-label={isDraggingEnabled ? 'Disable Dragging' : 'Enable Dragging'}
            className={`mr-1 h-8 w-[106px] px-3 bg-sidebarBackground rounded-lg border border-borderColor flex justify-center items-center text-13 font-semibold whitespace-nowrap ${isDraggingEnabled ? 'text-[var(--color-white)]' : 'text-[var(--color-w-50)]'
              } transition-colors duration-200`}
            onClick={onToggleDragging}
          >
            {isDraggingEnabled ? 'Dragging: On' : 'Dragging: Off'}
          </button>

          {/* Color Toggle Button */}
          <ColorToggleButton />
        </>
      )}
    </div>
  );
};

ZoomControls.propTypes = {
  zoomLevel: PropTypes.number.isRequired,
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  onResetZoom: PropTypes.func.isRequired,
  isDraggingEnabled: PropTypes.bool.isRequired,
  onToggleDragging: PropTypes.func.isRequired,
  profileData: PropTypes.shape({
    avatar: PropTypes.string,
    email: PropTypes.string,
    plan: PropTypes.string,
    tokens: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    position: PropTypes.string,
  }),
};

export default ZoomControls;

