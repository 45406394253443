import React, { useState, useEffect, useCallback } from 'react';
import { ReactComponent as InfoIcon } from '../Assets/info.svg';
import TooltipCard from './TooltipCard';
import { getPlanTooltipData } from './TooltipData';
import { getStripe } from '../../utils/stripe';
import { getAuth } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import '../../styles/globals.css';

export default function PlanCard({
  title,
  tokens,
  priceMonthly,
  priceQuarterly,
  priceYearly,
  features,
  hoverBackgroundImage,
  hoverBackgroundPosition,
  billingPeriod,
  priceId,
  savingsAmount,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [userHasActiveSubscription, setUserHasActiveSubscription] = useState(false);
  const tooltipData = getPlanTooltipData(title);
  const location = useLocation();

  // Fetch active plan from the backend for the authenticated user
  const fetchActivePlan = useCallback(async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const idToken = await user.getIdToken();
      try {
        const response = await fetch('https://www.ek0go8g.vudoo.ai/api/user-active-plan', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();

          // Debugging to check plan details from API and component
          console.log(`[Debug] Active plan received from backend: ${data.activePlan}`);
          console.log(`[Debug] Active Price ID from backend: ${data.activePriceId}`);
          console.log(`[Debug] Component's price ID: ${priceId}`);

          // Directly compare backend activePriceId with the priceId
          const isUserSubscribedToThisPlan = data.activePriceId && data.activePriceId === priceId;

          setUserHasActiveSubscription(!!data.activePriceId); // Update whether user has an active plan
          setIsActive(isUserSubscribedToThisPlan); // Update whether this specific card is active
        }
      } catch (error) {
        console.error('Error fetching active plan:', error);
      }
    } else {
      setUserHasActiveSubscription(false); // No user logged in
    }
  }, [priceId]);

  // Use effect to fetch active plan on component mount
  useEffect(() => {
    fetchActivePlan();
  }, [fetchActivePlan]);

  // Use effect to handle return from the Stripe checkout success
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
    const success = queryParams.get('success');

    if (sessionId && success) {
      console.log(`User returned from checkout. Session ID: ${sessionId}`);
      fetchActivePlan(); // Refetch active plan when user returns
    }
  }, [location.search, fetchActivePlan]);

  const handleCheckout = useCallback(async () => {
    if (isLoading) return; // Prevent checkout if loading
    setIsLoading(true);
    try {
      console.log(`Initiating checkout for priceId: ${priceId} in plan: ${title}`);

      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        alert("You need to be logged in to proceed with checkout.");
        setIsLoading(false); // Reset loading state if user is not logged in
        return;
      }

      const idToken = await user.getIdToken();

      const response = await fetch(
        "https://www.ek0go8g.vudoo.ai/api/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            priceId: priceId,
            mode: "subscription",
            success_url: `${window.location.origin}/profile?session_id={CHECKOUT_SESSION_ID}&success=true`,
            cancel_url: `${window.location.origin}/profile`,
          }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to create checkout session: ${response.statusText}`);
      }

      const { sessionId } = await response.json();
      const stripe = await getStripe();

      // Redirect to checkout
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error("Error during checkout:", error);
      alert("Checkout failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, priceId, title]);

  // Function to handle manage subscription
  const handleManageSubscription = useCallback(() => {
    window.open("https://billing.stripe.com/p/login/fZe8xe13S8ZJ1zOfYY", "_blank");
  }, []);

  // Determine if the card should be clickable
  const isCardClickable = isActive || !userHasActiveSubscription;

  // Determine if the hover functionality should be active
  const isHoverable = isCardClickable;

  return (
    <div
      className={`h-[400px] p-4 rounded-lg flex flex-col justify-between items-start shadow-md relative overflow-visible group 
        ${isCardClickable ? "cursor-pointer" : "opacity-50 cursor-not-allowed"} 
        ${
          isActive
            ? "border-2 border-[#5AF4A6] bg-[var(--coal-700)]"
            : "bg-[var(--coal-800)]"
        }`}
      onClick={() => {
        if (isActive) {
          handleManageSubscription();
        } else if (!userHasActiveSubscription) {
          handleCheckout();
        }
      }}
      role="button"
      tabIndex={isCardClickable ? 0 : -1}
    >
      {/* Container for Savings Banner and Info Icon */}
      <div
        className={`absolute top-w right-2 z-30 flex items-center gap-2 ${isCardClickable ? "" : "pointer-events-none"}`}
        onClick={(e) => e.stopPropagation()} // Prevent card click
        onKeyDown={(e) => e.stopPropagation()} // Prevent keyboard events
      >
        {/* Info Icon */}
        <div
          onMouseEnter={() => isHoverable && setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          role="button"
          tabIndex={isHoverable ? 0 : -1}
          aria-label="More information"
        >
          <InfoIcon className={`w-4 h-4 text-white ${isHoverable ? "opacity-80 cursor-pointer hover:opacity-100 transition-opacity duration-200" : "opacity-50"}`} />
        </div>
      </div>

      {/* Smooth Tooltip Card Display */}
      {isHoverable && (
        <div
          className={`absolute top-12 right-2 z-50 w-[260px] transition-all duration-300 ${
            showTooltip && tooltipData
              ? "opacity-100 transform translate-y-0"
              : "opacity-0 transform -translate-y-4 pointer-events-none"
          }`}
          style={{ overflow: 'visible' }}
        >
          {tooltipData ? (
            <TooltipCard title="Estimated token spend" items={tooltipData} />
          ) : (
            <div className="text-white text-xs">No data available</div>
          )}
        </div>
      )}

      {/* Hover Background Image */}
      {hoverBackgroundImage && (
        <div
          className={`rounded-md absolute inset-0 bg-cover bg-center transition-opacity duration-300 ${
            isHoverable ? "opacity-0 group-hover:opacity-100" : "opacity-0"
          }`}
          style={{
            backgroundImage: `url(${hoverBackgroundImage})`,
            backgroundPosition: hoverBackgroundPosition,
          }}
        >
          {/* Semi-transparent overlay to ensure text readability */}
          <div className="absolute inset-0 bg-black bg-opacity-0"></div>
        </div>
      )}

      {/* Title and Tokens */}
      <div className="flex-grow relative z-10">
        <div className={`text-white text-16 ${isHoverable ? "" : "opacity-50"}`}>{title}</div>
        <div className="flex items-baseline gap-1 mt-1">
          <span className={`text-4xl ${isHoverable ? "" : "opacity-50"}`} style={{ fontFamily: "'Inter', sans-serif" }}>
            {tokens}
          </span>
          <span className={`ml-0.5 text-white text-14 leading-tight ${isHoverable ? "" : "opacity-50"}`}>tokens/mo</span>
        </div>

        {/* Adjusted Price Display */}
        <div className={`text-14 text-white/70 leading-tight mt-4 flex items-center space-x-2 ${isHoverable ? "" : "opacity-50"}`}>
          <span>${Math.round(priceMonthly)} /mo</span>
          {billingPeriod === 'Yearly' && savingsAmount && (
            <div className="badge2 bg-[#FFCC69] text-black text-[10px] gap-2 font-bold py-0.3 px-2 rounded-md shadow-md whitespace-nowrap">
              Save ${Math.round(savingsAmount)}
            </div>
          )}
        </div>

        {/* Billing Period Information with Savings beside Yearly */}
        <div className={`text-14 text-white/70 leading-tight mt-1.5 flex items-center space-x-2 ${isHoverable ? "" : "opacity-50"}`}>
          <span className="text-white/50">
            {billingPeriod === "Yearly"
              ? `$${priceYearly} billed yearly`
              : billingPeriod === "Quarterly"
                ? `$${priceQuarterly} billed quarterly`
                : "Billed monthly"}
          </span>
        </div>
      </div>

      {/* Features List */}
      <div className="flex flex-col gap-2 mt-4 mb-2 relative z-10">
        {features.map((feature, index) => (
          <div key={index} className={`flex gap-3 ${isHoverable ? "" : "opacity-50"}`}>
            <div className={`w-1.5 h-1.5 shrink-0 ${isHoverable ? "bg-white/50" : "bg-white/20"} rounded-full mt-1.5`} />
            <div className={`text-white/70 text-13 leading-none ${isHoverable ? "" : "opacity-50"}`}>{feature}</div>
          </div>
        ))}
      </div>

      {/* Adding a margin to create space between the features and the button */}
      <div className="mt-auto w-full relative z-10">
        <div className="my-4" />
        {/* Upgrade Button with group-hover styles */}
        <div className="w-full">
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent click from bubbling up to the card
              if (isActive) {
                handleManageSubscription();
              } else if (!userHasActiveSubscription) {
                handleCheckout();
              }
            }}
            disabled={isLoading || (!isActive && userHasActiveSubscription)}
            className={`w-full h-10 rounded-md border border-white/20 text-white text-16-m
              transition-all duration-300 ${
                isLoading || (!isActive && userHasActiveSubscription)
                  ? "opacity-50 cursor-not-allowed"
                  : "group-hover:bg-[#d5d5d5] group-hover:text-black hover:opacity-90"
              }`}
          >
            {isLoading
              ? "Processing..."
              : isActive
                ? "Manage Subscription"
                : `Upgrade to ${title.split(" ")[0]}`}
          </button>
        </div>
      </div>
    </div>
  );
}
