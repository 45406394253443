import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UsageHistoryRow from './UsageHistoryRow';
import Cookies from 'js-cookie';
import { Spin, message } from 'antd';

const UsageHistorySection = () => {
  const [usageHistory, setUsageHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUsageHistory = async () => {
    const token = Cookies.get('token');
    if (!token) {
      message.error('You must be logged in to view usage history.');
      setLoading(false);
      return;
    }
  
    try {
      const [restoredResponse, upscaledResponse] = await Promise.all([
        fetch(`https://www.ek0go8g.vudoo.ai/api/imageRestoreGallery`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        }),
        fetch(`https://www.ek0go8g.vudoo.ai/api/imageUpscaleGallery`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
  
      if (!restoredResponse.ok || !upscaledResponse.ok) {
        throw new Error('Failed to fetch usage history');
      }
  
      const [restoredData, upscaledData] = await Promise.all([
        restoredResponse.json(),
        upscaledResponse.json(),
      ]);
  
      console.log('Raw Restored Data:', restoredData);
      console.log('Raw Upscaled Data:', upscaledData);
  
      // Normalize restored data
      const normalizedRestoredImages = (restoredData.sessions || []).flatMap((session) =>
        (session.variants || [])
          .filter((variant) => variant.tool !== 'original') // Remove "original" images
          .map((variant) => ({
            jobId: variant.id || 'unknown',
            dateProcessed: variant.dateProcessed || session.uploadDate,
            tool: variant.tool || 'Image Restoration',
            tokensSpent: variant.tokensSpent || 0,
            thumbnailUrl: variant.url || session.originalImage?.url,
          }))
      );
  
      // Normalize upscaled data
      const normalizedUpscaledImages = (upscaledData.sessions || []).flatMap((session) =>
        (session.variants || [])
          .filter((variant) => variant.tool !== 'original') // Remove "original" images
          .map((variant) => ({
            jobId: variant.id || 'unknown',
            dateProcessed: variant.dateProcessed || session.uploadDate,
            tool: variant.tool || 'Image Upscaling',
            tokensSpent: variant.tokensSpent || 0,
            thumbnailUrl: variant.url || session.originalImage?.url,
          }))
      );
  
      console.log('Normalized Restored Images:', normalizedRestoredImages);
      console.log('Normalized Upscaled Images:', normalizedUpscaledImages);
  
      // Combine and deduplicate data by jobId
      const combinedData = [...normalizedRestoredImages, ...normalizedUpscaledImages];
      const uniqueData = combinedData.filter(
        (item, index, self) => self.findIndex((i) => i.jobId === item.jobId) === index
      );
  
      // Sort data
      uniqueData.sort((a, b) => new Date(b.dateProcessed) - new Date(a.dateProcessed));
  
      console.log('Combined and Deduplicated Usage History:', uniqueData);
  
      setUsageHistory(uniqueData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching usage history:', error);
      setError('Failed to load usage history. Please try again later.');
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchUsageHistory(); // Fetch usage history when the component mounts
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#151515] text-white flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <div className="pt-2 border-t border-white/15">
      {/* Display Error Message if Any */}
      {error && <div className="text-red-500 mb-4 text-center">{error}</div>}

      {/* Usage History Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full text-left">
          <thead>
            <tr className="text-14 text-white/50">
              <th className="pr-4 py-2 font-normal">IMAGE</th>
              <th className="px-4 py-2 font-normal">JOB ID</th>
              <th className="px-4 py-2 font-normal">DATE PROCESSED</th>
              <th className="px-4 py-2 font-normal">TOOL</th>
              <th className="px-4 py-2 font-normal">TOKENS SPENT</th>
            </tr>
          </thead>
          <tbody>
            {usageHistory.length > 0 ? (
              usageHistory.map((item, index) => (
                <UsageHistoryRow key={`usage-${index}`} item={item} />
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center text-gray-400">No usage history found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

UsageHistorySection.propTypes = {
  usageHistory: PropTypes.arrayOf(
    PropTypes.shape({
      jobId: PropTypes.string.isRequired,
      dateProcessed: PropTypes.string.isRequired,
      tool: PropTypes.string.isRequired,
      tokensSpent: PropTypes.number.isRequired,
      thumbnailUrl: PropTypes.string,
    })
  ),
};

export default UsageHistorySection;
