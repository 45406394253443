import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import Cookies from "js-cookie";
import ActionCard from "../components/sharedCards/ActionCard";
import GalleryItem from "../components/sharedCards/GalleryItem";
import ZoomControls from "../components/shared/ZoomControls";
import ImageModal from "../components/ImageModal";
import Lottie from "react-lottie";
import animationData from "../components/Assets/vudoo-logo-animation.json";
import Hotjar from "@hotjar/browser";
import { onIdTokenChanged } from "firebase/auth";
import { auth } from "../firebase";import MobileWarningPopup from './MobileWarningPopup'; // Import the MobileWarningPopup


// Import hover background images
import restoreHoverImage from "../components/Assets/restore-card.png";
import upscaleHoverImage from "../components/Assets/upscale-card.png";
import videoUpscaleHoverImage from "../components/Assets/video-card.png";

const LandingPageMobile = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  // State Variables
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    position: "",
    avatar: "/placeholder.svg?height=64&width=64",
  });
  const [userCredits, setUserCredits] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [loadingGallery, setLoadingGallery] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [showMobileWarning, setShowMobileWarning] = useState(false); // State to show the popup


  // Lottie options for the logo animation
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 640) {
        setShowMobileWarning(true); // Show popup on mobile
      } else {
        setShowMobileWarning(false);
      }
    };

    handleResize(); // Check the screen size initially
    window.addEventListener('resize', handleResize); // Add event listener

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up on unmount
    };
  }, []);

  // Swipe Handlers
  const handleSwipe = (direction) => {
    if (direction === "LEFT") {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % actionCards.length);
    } else if (direction === "RIGHT") {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + actionCards.length) % actionCards.length
      );
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe("LEFT"),
    onSwipedRight: () => handleSwipe("RIGHT"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // ActionCards Array
  const actionCards = [
    {
      title: "Image Restore",
      description: "Enhance, repair and restore old or damaged photos back to life.",
      buttonText: "New Restore",
      onButtonClick: () => navigate("/upload?type=restoration"),
      hoverBackgroundImage: restoreHoverImage,
      betaBadge: true,
    },
    {
      title: "Image Upscale",
      description: "Increase resolution and enhance details for lossless, pro-grade results.",
      buttonText: "New Upscale",
      onButtonClick: () => navigate("/upload?type=upscaler"),
      hoverBackgroundImage: upscaleHoverImage,
    },
    {
      title: "Video Upscale",
      description:
        "Improve video resolution and quality. AI processing creates pro-grade footage for your projects.",
      comingSoon: true,
      hoverBackgroundImage: videoUpscaleHoverImage,
    },
  ];

  // Handle Zoom
  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 10, 200));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 10, 50));
  };

  // Handle Image Click
  const handleImageClick = (index) => {
    setSelectedIndex(index);
    setIsModalOpen(true);
  };

  // Handle Modal Close
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedIndex(0);
  };

  // Fetch User Profile
  const fetchUserProfile = async () => {
    const token = Cookies.get("token");
    if (!token) {
      setIsLoggedIn(false);
      setLoadingGallery(false);
      return;
    }

    try {
      const response = await fetch("https://www.ek0go8g.vudoo.ai/api/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch user profile");

      const data = await response.json();
      setProfileData({
        name: data.name || "User",
        email: data.email || "",
        position: data.position || "",
        avatar: data.photoURL || "/placeholder.svg?height=64&width=64",
      });

      setUserCredits(data.credits || 0);
      setIsLoggedIn(true);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setIsLoggedIn(false);
    }
  };

  // Fetch Gallery Images
  const fetchGalleryImages = async () => {
    const token = Cookies.get("token");
    if (!token) {
      setLoadingGallery(false);
      return;
    }

    try {
      const [restoredResponse, upscaledResponse] = await Promise.all([
        fetch("https://www.ek0go8g.vudoo.ai/api/imageRestoreGallery", {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }),
        fetch("https://www.ek0go8g.vudoo.ai/api/imageUpscaleGallery", {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      if (!restoredResponse.ok || !upscaledResponse.ok) {
        throw new Error("Failed to fetch images");
      }

      const [restoredData, upscaledData] = await Promise.all([
        restoredResponse.json(),
        upscaledResponse.json(),
      ]);

      const normalizedRestoredImages = (restoredData.images || []).map((img) => ({
        ...img,
        uploadDate: img.uploadedAt || img.createdAt,
        toolUsed: "Image Restoration",
      }));

      const normalizedUpscaledImages = (upscaledData.images || []).map((img) => ({
        ...img,
        uploadDate: img.uploadedAt || img.createdAt,
        toolUsed: "Image Upscaling",
      }));

      const combinedImages = [
        ...normalizedRestoredImages,
        ...normalizedUpscaledImages,
      ].sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));

      setGalleryImages(combinedImages);
      setLoadingGallery(false);
    } catch (error) {
      console.error("Error fetching images:", error);
      setLoadingGallery(false);
    }
  };

  // Initialize Hotjar
  useEffect(() => {
    Hotjar.init(
      process.env.REACT_APP_HOTJAR_ID,
      process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
    );
  }, []);

  // Firebase Auth Listener
  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const idToken = await user.getIdToken(true);
        Cookies.set("token", idToken, { expires: 1, path: "/" });
        setIsLoggedIn(true);
        await fetchUserProfile();
        await fetchGalleryImages();
      } else {
        setIsLoggedIn(false);
        Cookies.remove("token");
      }
    });

    return () => unsubscribe();
  }, []);

  // Initial Data Fetch
  useEffect(() => {
    const fetchData = async () => {
      await fetchUserProfile();
      await fetchGalleryImages();
    };
    fetchData();
  }, []);

  return (
    <div {...handlers} className="min-h-screen bg-[var(--coal-900)] text-white">
      {/* Header with Lottie Animation */}
      <header className="fixed top-0 left-0 right-0 flex justify-between items-center px-4  py-4 bg-[var(--coal-900)] z-50">
        <div className="flex items-center">
          <button
            className="focus:outline-none"
            onClick={() => navigate("/home")}
            style={{ backgroundColor: "transparent", border: "none" }}
            aria-label="Go to Home"
          >
            <Lottie options={defaultOptions} height={16} width={"auto"} />
          </button>
        </div>
        <div
          className="px-3 py-2 rounded-lg border border-[var(--color-border)] bg-[var(--coal-800)] text-sm cursor-pointer hover:bg-[#2c2c2c] transition-colors duration-300"
          onClick={() => navigate(isLoggedIn ? "/profile" : "/login")}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              navigate(isLoggedIn ? "/profile" : "/login");
            }
          }}
          aria-label={isLoggedIn ? "Go to Profile" : "Go to Login"}
        >
          {userCredits !== null ? `${userCredits} tokens left` : "Fetching..."}
        </div>
      </header>

      {/* ActionCards Swipeable Section */}
      <div className="flex flex-col items-center mt-20 mb-8">
        <div className="w-full max-w-xs">
          <ActionCard {...actionCards[currentIndex]} />
        </div>
        <div className="flex justify-center mt-4 space-x-2">
          {actionCards.map((_, index) => (
            <div
              key={index}
              className={`h-2 w-2 rounded-full ${
                index === currentIndex ? "bg-white" : "bg-gray-400"
              }`}
            ></div>
          ))}
        </div>
      </div>

      {/* Gallery Images Section */}
      <div className="flex flex-col gap-8 border-t border-white/20 mx-auto pt-8 px-4 w-full relative z-10">
        <div className="flex justify-between items-start mb-4 relative z-10">
          <div className="flex flex-col gap-4 w-full">
            <div className="flex space-x-4">
              <div className="text-white px-1 pt-2 border-t-2 border-white">My Library</div>
            </div>
          </div>
        </div>

        <div
          className="grid gap-4 pt-4"
          style={{
            gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
          }}
        >
          {loadingGallery ? (
            <div className="text-white text-center col-span-full">Loading images...</div>
          ) : galleryImages.length > 0 ? (
            galleryImages.map((image, index) => (
              <GalleryItem
                key={image.id}
                image={image}
                onClick={() => handleImageClick(index)}
              />
            ))
          ) : (
            <div className="text-white text-center col-span-full">
              No images found. Start by uploading your first image!
            </div>
          )}
        </div>
      </div>

      {/* ZoomControls Component */}
      <ZoomControls zoomLevel={zoomLevel} onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />

      {/* Image Modal */}
      <ImageModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        images={galleryImages}
        initialIndex={selectedIndex}
        className={`transition-all duration-300 ease-in-out ${
          isModalOpen ? "opacity-100 scale-100" : "opacity-0 scale-95"
        }`}
      />
        {/* Mobile Warning Popup */}
        <MobileWarningPopup 
        isVisible={showMobileWarning} 
        closePopup={() => setShowMobileWarning(false)} 
      />
    </div>
  );
};

export default LandingPageMobile;
