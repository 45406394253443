// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.library-section {
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
  }
  
  .spacing-container {
    margin-top: 20px; /* Adjust as needed */
    margin-bottom: 16px; /* Adjust to match LandingPage */
  }

  @media (max-width: 1024px) {
    .grid-cols-4 {
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }
  }
  
  @media (max-width: 768px) {
    .grid-cols-4 {
      grid-template-columns: 1fr;
    }
  }
  

  .library-section-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 15vh;
    background-color: #151515;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}


`, "",{"version":3,"sources":["webpack://./src/styles/LibraryLearnSection.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,gBAAgB,EAAE,qBAAqB;IACvC,mBAAmB,EAAE,gCAAgC;EACvD;;EAEA;IACE;MACE,2DAA2D;IAC7D;EACF;;EAEA;IACE;MACE,0BAA0B;IAC5B;EACF;;;EAGA;IACE,eAAe;IACf,SAAS;IACT,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,yBAAyB;IACzB,8CAA8C;IAC9C,eAAe;IACf,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;AAC3B","sourcesContent":[".library-section {\n    width: 100%;\n    margin: 0 auto;\n    padding: 0 16px;\n  }\n  \n  .spacing-container {\n    margin-top: 20px; /* Adjust as needed */\n    margin-bottom: 16px; /* Adjust to match LandingPage */\n  }\n\n  @media (max-width: 1024px) {\n    .grid-cols-4 {\n      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));\n    }\n  }\n  \n  @media (max-width: 768px) {\n    .grid-cols-4 {\n      grid-template-columns: 1fr;\n    }\n  }\n  \n\n  .library-section-container {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    height: 15vh;\n    background-color: #151515;\n    border-top: 1px solid rgba(255, 255, 255, 0.2);\n    padding: 0 20px;\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
