import React from 'react';

const TooltipCard = ({ title, items = [] }) => {
  return (
    <div className="w-[260px] px-4 py-3 bg-[#212121] rounded-lg border border-white/10 flex flex-col justify-start items-start gap-4">
      <div className="text-white text-sm font-normal leading-tight">{title}</div>
      {/* Map through items if it’s an array */}
      {items.length > 0 ? (
        items.map((item, index) => (
          <div key={index} className="flex-col justify-start items-start gap-0.5 flex">
            {/* Display the input resolution */}
            <div className="text-white/50 text-sm font-normal leading-tight">
              Input of {item.input}
            </div>
            {/* Map through the nested details */}
            {item.details && item.details.length > 0 ? (
              item.details.map((detail, subIndex) => (
                <div key={subIndex} className="w-full h-5 justify-start items-start gap-4 inline-flex">
                  <div className="text-white text-sm font-normal leading-tight">
                    {detail.images} images to {detail.output}
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full h-5 justify-start items-start gap-4 inline-flex">
                <div className="text-white/50 text-sm font-normal leading-tight">
                  No details available
                </div>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="text-white/50 text-sm font-normal leading-tight">No data available</div>
      )}
    </div>
  );
};

export default TooltipCard;
