import React from 'react';
import PropTypes from 'prop-types';

const DownloadSection = ({
  outputResolution,
  handleDownload,
  isCollapsed,
  toggleCollapse,
  minimalView = false,
}) => {
  const handleDownloadClick = (format) => {
    handleDownload(format); // Pass only the format
  };

  return (
    <div
      className={`w-full ${minimalView ? 'bg-transparent' : 'bg-[#212121]'} rounded-lg border border-white/10 flex-col justify-center items-start p-3 `}
    >
      {/* Title Section */}
      <div
        className={`self-stretch pl-1 pr-2 py-1 justify-between items-center flex ${minimalView ? '' : 'cursor-pointer'}`}
        onClick={!minimalView ? toggleCollapse : null}
      >
        <div className="text-16 leading-snug">{minimalView ? 'Download' : 'Download'}</div>
        <div className="justify-start items-center gap-2.5 flex">
          <div
            className={`w-2 h-2 rounded-full transform transition-transform duration-300 ${isCollapsed ? 'bg-white opacity-30' : 'bg-white'}`}
          />
        </div>
      </div>

      <div
        className={`transition-max-height duration-500 ease-in-out ${
          isCollapsed ? 'max-h-0' : 'max-h-screen'
        } overflow-hidden`}
      >
        {!isCollapsed && (
          <>
            {!minimalView && (
              <div className="w-full h-7 px-1 justify-between items-center flex">
                <div className="text-white text-14 leading-tight opacity-50">Output resolution</div>
                <div className="text-white text-14 font-normal leading-tight">{outputResolution}</div>
              </div>
            )}

            {/* Divider */}
            <div className="self-stretch h-px opacity-10 bg-white my-2 mx-1" />

            {/* Buttons section */}
            <div className="self-stretch px-1 py-2 justify-between items-center flex">
              <div className="text-16">{minimalView ? 'Choose Format' : 'Download as'}</div>
              <div className="flex justify-start items-center gap-2">
                <div
                  className="w-20 h-10 bg-[#d5d5d5] rounded-lg justify-center items-center flex cursor-pointer hover:opacity-80"
                  onClick={() => handleDownloadClick('png')}
                >
                  <div className="text-[#151515] text-sm font-medium leading-snug">PNG</div>
                </div>
                <div
                  className="w-20 h-10 bg-[#d5d5d5] rounded-lg justify-center items-center flex cursor-pointer hover:opacity-80"
                  onClick={() => handleDownloadClick('jpg')}
                >
                  <div className="text-[#151515] text-sm font-medium leading-snug">JPG</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

DownloadSection.propTypes = {
  outputResolution: PropTypes.string.isRequired,
  handleDownload: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
  minimalView: PropTypes.bool,
};

export default DownloadSection;
