// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { initializeFirebase } from './firebase';

// Initialize Firebase
initializeFirebase().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}).catch((error) => {
  console.error('Error initializing Firebase:', error);
});