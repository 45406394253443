import React, { useState, useEffect } from 'react';
import Header from './Header';
import ZoomControls from './ZoomControls';
import MobileWarningPopup from '../MobileWarningPopup';

const SharedLayout = ({
  headerProps,
  sidebarContent,
  zoomControlsProps,
  children,
}) => {
  const [isDraggingEnabled, setIsDraggingEnabled] = useState(false);
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  const toggleDragging = () => {
    setIsDraggingEnabled((prev) => !prev);
  };

  // Extend zoomControlsProps with dragging state and toggle function
  const extendedZoomControlsProps = {
    ...zoomControlsProps,
    isDraggingEnabled,
    onToggleDragging: toggleDragging,
  };

  // Clone the children (ImageDisplay) and pass isDraggingEnabled and showSlider props
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        isDraggingEnabled,
        showSlider: true, // Ensure the slider is always visible in ImageDisplay
      });
    }
    return child;
  });

  // Detect screen resolution
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 640) {
        setShowMobileWarning(true); // Show popup on mobile
      } else {
        setShowMobileWarning(false);
      }
    };

    handleResize(); // Check the screen size initially
    window.addEventListener('resize', handleResize); // Add event listener

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up on unmount
    };
  }, []);

  return (
    <div className="min-h-screen bg-darkBackground text-textLight relative">
      {/* Header with consistent z-index */}
      <div className="relative z-50">
        <Header {...headerProps} />
      </div>

      {/* Floating Sidebar - Fixed Position */}
      <div
        className="fixed top-1/2 right-3 transform -translate-y-1/2 overflow-y-auto max-h-[calc(100vh-32px)] flex-shrink-0 z-40 pointer-events-auto"
      >
        <div className="flex justify-center flex-col gap-3 backdrop-blur-md shadow-lg my-4 w-80">
          {sidebarContent}
        </div>
      </div>

      {/* Main Content Container - Scrollable */}
      <div className="flex h-screen w-full relative overflow-y-scroll pr-96 pl-10">
        {/* Centered Image Display */}
        <div className="flex-grow flex justify-center items-center">
          <div className="w-full h-[calc(100vh-32px)] max-w-screen-2xl flex justify-center items-center">
            {childrenWithProps}
          </div>
        </div>

        {/* Zoom Controls */}
        <div className="fixed bottom-4 left-4 z-40">
          <ZoomControls {...extendedZoomControlsProps} />
        </div>
      </div>

      {/* Mobile Warning Popup */}
      <MobileWarningPopup
        isVisible={showMobileWarning}
        closePopup={() => setShowMobileWarning(false)}
      />
    </div>
  );
};

export default SharedLayout;
