import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Spin } from "antd";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Lottie from "react-lottie";
import animationData from "../components/Assets/vudoo-logo-animation.json";
import AccountSection from "../components/sharedProfile/AccountSection";
import UsageHistorySection from "../components/sharedProfile/UsageHistorySection";
import PlansSection from "../components/sharedProfile/PlansSection";
import "../styles/ProfilePage.css";
import Hotjar from "@hotjar/browser";
import MobileWarningPopup from "./MobileWarningPopup"; // Import the MobileWarningPopup

export default function CombinedPageMobile() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState("account");
  const [userCredits, setUserCredits] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [usageHistory, setUsageHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    position: "",
    avatar: "/placeholder.svg?height=64&width=64",
  });
  const [showMobileWarning, setShowMobileWarning] = useState(false); // State to show the mobile warning

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Hotjar Integration
  useEffect(() => {
    Hotjar.init(
      process.env.REACT_APP_HOTJAR_ID,
      process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
    );
  }, []);

  // Detect screen resolution and show mobile warning if needed
  useEffect(() => {
    const handleResize = () => {
      const hasSeenWarning = localStorage.getItem("seenMobileWarning");
      if (window.innerWidth <= 640 && !hasSeenWarning) {
        setShowMobileWarning(true); // Show popup on mobile if not seen before
      } else {
        setShowMobileWarning(false);
      }
    };

    handleResize(); // Check the screen size initially
    window.addEventListener("resize", handleResize); // Add event listener

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on unmount
    };
  }, []);

  const closePopup = () => {
    setShowMobileWarning(false);
    localStorage.setItem("seenMobileWarning", "true");
  };

  // Fetch user profile data
  const fetchUserProfile = async () => {
    const token = Cookies.get("token");
    if (!token) {
      setIsLoggedIn(false);
      setLoading(false);
      return;
    }
    try {
      const response = await fetch(
        "https://www.ek0go8g.vudoo.ai/api/profile",
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch user profile");
      const data = await response.json();
      const nameParts = data.name ? data.name.split(" ") : ["User"];
      setProfileData({
        firstName: nameParts[0] || "User",
        lastName: nameParts.slice(1).join(" ") || "",
        email: data.email || "",
        avatar: data.photoURL || "/placeholder.svg?height=64&width=64",
        position: data.position || "",
      });
      setUserCredits(data.credits || 0);
      setIsLoggedIn(true);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setIsLoggedIn(false);
    }
  };

  // Initial data fetch
  useEffect(() => {
    const initialize = async () => {
      await fetchUserProfile();
      setLoading(false);
    };

    if (location.state && location.state.section) {
      setCurrentPage(location.state.section);
    }

    initialize();
  }, [location.state]);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#151515] text-white flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#151515] text-white flex flex-col">
      <header className="fixed top-0 left-0 right-0 flex justify-between items-center h-16 px-4 z-50 bg-transparent">
        {/* Logo Animation */}
        <button
          className="flex items-center focus:outline-none relative z-50"
          onClick={() => navigate("/home")}
          style={{ backgroundColor: "transparent", border: "none" }}
          aria-label="Go to Home"
        >
          <Lottie options={defaultOptions} height={16} width={"auto"} />
        </button>

        {/* Tokens Left Button */}
        <div
          className="px-3 py-2 rounded-lg border border-[var(--color-border)] bg-[var(--coal-800)] text-sm cursor-pointer hover:bg-[#2c2c2c] transition-colors duration-300 relative z-50"
          onClick={() => navigate(isLoggedIn ? "/profile" : "/login")}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              navigate(isLoggedIn ? "/profile" : "/login");
            }
          }}
          aria-label={isLoggedIn ? "Go to Profile" : "Go to Login"}
        >
          {userCredits !== null ? `${userCredits} tokens left` : "Fetching..."}
        </div>
      </header>

      {/* Main Content */}
      <div className="flex flex-col w-full max-w-full px-4 pt-20 overflow-y-auto">
        <nav className="flex justify-evenly pb-4 sticky bottom-0 bg-[#151515]">
          {[ 
            { id: "dashboard", label: "Home" },
            { id: "account", label: "Account" },
            { id: "usage", label: "Usage History" },
            { id: "plans", label: "Plans" },
          ].map((item) => (
            <button
              key={item.id}
              className={`text-[14px] px-3 py-1 rounded-lg ${
                currentPage === item.id
                  ? "bg-white text-black"
                  : "text-white bg-gray-600"
              } hover:bg-gray-500 transition-colors`}
              onClick={() => {
                if (item.id === "dashboard") {
                  navigate("/home");
                } else {
                  setCurrentPage(item.id);
                }
              }}
            >
              {item.label}
            </button>
          ))}
        </nav>

        <main className="flex-1 p-4">
          <TransitionGroup>
            {currentPage === "account" && (
              <CSSTransition key="account" timeout={300} classNames="fade">
                <AccountSection
                  profileData={profileData}
                  userCredits={userCredits}
                />
              </CSSTransition>
            )}
            {currentPage === "usage" && (
              <CSSTransition key="usage" timeout={300} classNames="fade">
                <UsageHistorySection usageHistory={usageHistory} />
              </CSSTransition>
            )}
            {currentPage === "plans" && (
              <CSSTransition key="plans" timeout={300} classNames="fade">
                <PlansSection />
              </CSSTransition>
            )}
          </TransitionGroup>
        </main>
      </div>

      {/* Mobile Warning Popup */}
      <MobileWarningPopup isVisible={showMobileWarning} closePopup={closePopup} />
    </div>
  );
}
