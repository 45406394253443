// frontend/src/pages/LoginPage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider, onIdTokenChanged } from 'firebase/auth';
import { auth } from '../firebase';
import Cookies from 'js-cookie';
import Lottie from 'react-lottie';
import animationData from '../components/Assets/vudoo-logo-animation.json';
import background from '../components/Assets/background.png';
import Hotjar from '@hotjar/browser';
import MobileWarningPopup from './MobileWarningPopup'; // Import the MobileWarningPopup

export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [lastUsed, setLastUsed] = useState('');
  const [showMobileWarning, setShowMobileWarning] = useState(false); // State to show the popup
  const navigate = useNavigate();
  const googleProvider = new GoogleAuthProvider();

  // Hotjar Integration
  useEffect(() => {
    Hotjar.init(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
  }, []);

  // Detect screen resolution
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowMobileWarning(true); // Show popup on mobile
      } else {
        setShowMobileWarning(false);
      }
    };

    handleResize(); // Check the screen size initially
    window.addEventListener('resize', handleResize); // Add event listener

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up on unmount
    };
  }, []);

  // Retrieve last used method from local storage
  useEffect(() => {
    const savedLastUsed = localStorage.getItem('lastUsed');
    if (savedLastUsed) setLastUsed(savedLastUsed);
  }, []);

  // Set up token refresh listener on component mount
  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const idToken = await user.getIdToken(true); // Fetch the refreshed token
        Cookies.set('token', idToken, { expires: 1, path: '/' }); // Update the token in cookies
      } else {
        Cookies.remove('token'); // Remove token if user logs out or session expires
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, []);

  // Visibility change handler to refresh token
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        const user = auth.currentUser;
        if (user) {
          const idToken = await user.getIdToken(true); // Force refresh the token
          Cookies.set('token', idToken, { expires: 1, path: '/' }); // Update the token in cookies
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Background refresh of the token every 30 minutes
  useEffect(() => {
    const refreshTokenInterval = setInterval(async () => {
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken(true); // Force a refresh of the ID token
        Cookies.set('token', idToken, { expires: 1, path: '/' });
      }
    }, 30 * 60 * 1000); // Refresh every 30 minutes

    return () => clearInterval(refreshTokenInterval); // Cleanup interval on unmount
  }, []);

  const handleGoogleLogin = async () => {
    setError('');
    setLoading(true);

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const idToken = await result.user.getIdToken(); // Obtain the ID Token

      // Send the ID Token to the backend for verification/authentication
      const response = await fetch('https://www.ek0go8g.vudoo.ai/api/auth', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idToken, type: 'google' }), // Remove userId from payload
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Google sign-in failed');
      }

      // **Do not receive a new idToken from the backend**
      // Instead, continue using the idToken obtained from Firebase SDK

      // Optionally, store the idToken in cookies
      Cookies.set('token', idToken, { expires: 1, path: '/' });

      // Navigate to the home page
      localStorage.setItem('lastUsed', 'google');
      setLastUsed('google');

      navigate('/home');
    } catch (error) {
      setError('An error occurred during Google Sign-In. Please try again.');
      console.error('Google Sign-In Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-[#0A0A0A] bg-cover bg-center"
      style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    >
      <header className="absolute top-4 left-4 flex items-center cursor-pointer" onClick={() => navigate('/')}>
        <Lottie
          options={{ loop: true, autoplay: true, animationData, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }}
          height={16}
          width="auto"
        />
      </header>

      <div className="w-full max-w-[500px]">
        <div className="bg-[#151515] rounded-lg p-8 shadow-xl transition-all duration-300">
          <h2 className="text-2xl font-serif text-white mb-4">Sign in</h2>
          <p className="text-gray-400 mb-8">
            Welcome to Vudoo!{' '}
            <span className="text-gray-400 mb-8">
              Continue with Google
            </span>
          </p>
          <div className="space-y-3 mb-8">
            <button
              onClick={handleGoogleLogin}
              disabled={loading}
              className="h-[56px] w-full bg-[#d5d5d5] rounded-md border border-white/20 flex items-center justify-center relative transition-all duration-300 ease-in-out hover:bg-opacity-90 hover:bg-[#d5d5d5]"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 23 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <path
                  opacity="0.5"
                  d="M10.95 9.625V12.375C10.95 12.8304 11.319 13.2 11.775 13.2H17.7199C17.4455 13.9788 17.0292 14.6839 16.5072 15.2928L19.8254 18.1687C21.4875 16.2409 22.5 13.739 22.5 11C22.5 10.5446 22.4648 10.0716 22.3889 9.5139C22.3334 9.10525 21.9841 8.8 21.5716 8.8H11.775C11.319 8.8 10.95 9.1696 10.95 9.625ZM5.2366 13.0686C5.021 12.4173 4.9 11.7227 4.9 11C4.9 10.2773 5.021 9.58265 5.2366 8.93145L1.63025 6.17375C0.9136 7.6329 0.5 9.26695 0.5 11C0.5 12.7331 0.9136 14.3671 1.6308 15.8263L5.2366 13.0686ZM15.2758 16.4093C14.1983 17.1622 12.8926 17.6 11.5 17.6C9.1834 17.6 7.14565 16.3977 5.96755 14.5871L2.46845 17.2629C4.45725 20.1218 7.7611 22 11.5 22C14.2324 22 16.7294 20.9924 18.6549 19.338L15.2758 16.4093ZM18.9333 2.91335C16.8972 1.03455 14.2572 0 11.5 0C7.7611 0 4.45725 1.87825 2.46845 4.7377L5.96755 7.41345C7.14565 5.6023 9.1834 4.4 11.5 4.4C13.1192 4.4 14.6768 4.99455 15.8857 6.07365C16.2119 6.36515 16.7096 6.35085 17.0187 6.0412L18.9574 4.103C19.1164 3.9446 19.2033 3.72735 19.1989 3.50295C19.1945 3.27855 19.0982 3.0657 18.9333 2.91335Z"
                  fill="black"
                />
              </svg>
              <div className="text-black text-base font-medium">Google</div>
              {lastUsed === 'google' && (
                <div className="absolute right-3 text-xs text-gray-700">Last used</div>
              )}
            </button>
          </div>
          {error && <p className="mt-4 text-red-500">{error}</p>}
        </div>
      </div>

      {/* Mobile Warning Popup */}
      <MobileWarningPopup 
        isVisible={showMobileWarning} 
        closePopup={() => setShowMobileWarning(false)} 
      />
    </div>
  );
}
