// src/components/AccountSection.js

import React, { useState, useContext, useEffect,  useCallback  } from 'react';
import InputField from './InputField';
import Cookies from 'js-cookie';
import { ProfileContext } from '../../ProfileContext'; // Adjust the path as necessary
import { message } from 'antd';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

const AccountSection = ({ profileData, userCredits, onUpdateProfile }) => {
  const navigate = useNavigate();
  const { setAvatar: setContextAvatar } = useContext(ProfileContext); // Get setAvatar from context
  const storedAvatar = localStorage.getItem('profileAvatar') || profileData.avatar;
  const storedPosition = localStorage.getItem('profilePosition') || profileData.position;

  const [selectedPosition, setSelectedPosition] = useState(storedPosition);
  const [avatar, setAvatar] = useState(storedAvatar);
  const [isUploading, setIsUploading] = useState(false);
  const [firstName, setFirstName] = useState(profileData.firstName);
  const [lastName, setLastName] = useState(profileData.lastName);
  const [activePlan, setActivePlan] = useState(null);
  const [isLoadingPlan, setIsLoadingPlan] = useState(true);

  const positions = [
    'AI Artist',
    'AI Enthusiast',
    'Animator',
    'Art Director',
    'Book Cover Designer',
    'Brand Manager',
    'Campaign Manager',
    'Content Creator',
    'Creative Director',
    'Digital Marketer',
    'E-commerce Manager',
    'Freelance Creative Professional',
    'Game Artist',
    'Game Dev',
    'Graphic Designer',
    'Hobbyist Digital Artist',
    'Illustrator',
    'Marketing Consultant',
    'Motion Graphics Designer',
    'Photographer',
    'Product Photographer',
    'Social Media Specialist',
    'Student in Design/Art',
    'UI/UX Designer',
    'Video Editor',
    'Videographer',
    'Web Dev',
    "I'm just here for fun"
  ];

  const fetchActivePlan = useCallback(async () => {
    setIsLoadingPlan(true);
    try {
      const token = Cookies.get('token');
      if (!token) {
        throw new Error('User not authenticated');
      }

      const response = await fetch('https://www.ek0go8g.vudoo.ai/api/user-active-plan', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setActivePlan(data.activePlan);
      } else {
        console.error("Failed to fetch active plan");
      }
    } catch (error) {
      console.error("Error fetching active plan:", error);
    } finally {
      setIsLoadingPlan(false);
    }
  }, []);

  useEffect(() => {
    fetchActivePlan();
  }, [fetchActivePlan]);


  // Update local state when profileData changes (e.g., after fetching or saving)
  useEffect(() => {
    setFirstName(profileData.firstName);
    setLastName(profileData.lastName);
  }, [profileData.firstName, profileData.lastName]);

  const resizeImage = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;
      };

      img.onload = () => {
        const canvas = document.createElement('canvas');
        let { width, height } = img;

        const maxDimension = 200;
        if (width > height) {
          if (width > maxDimension) {
            height = Math.round((height *= maxDimension / width));
            width = maxDimension;
          }
        } else {
          if (height > maxDimension) {
            width = Math.round((width *= maxDimension / height));
            height = maxDimension;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        const resizedDataUrl = canvas.toDataURL('image/jpeg', 0.9);
        resolve(resizedDataUrl);
      };

      img.onerror = (error) => reject(error);
      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        alert('File size exceeds 5MB.');
        return;
      }

      try {
        const resizedImage = await resizeImage(file);
        setAvatar(resizedImage);
      } catch (error) {
        console.error('Error resizing image:', error);
        alert('Failed to resize the image. Please try a different file.');
      }
    }
  };

  const handleCancel = () => {
    setSelectedPosition(storedPosition);
    setAvatar(storedAvatar);
    setFirstName(profileData.firstName);
    setLastName(profileData.lastName);
  };

  const handleSave = async () => {
    setIsUploading(true);
    try {
      const token = Cookies.get('token');
      if (!token) {
        throw new Error('User not authenticated');
      }

      // Prepare data to be sent to the server
      const updateData = {};
      let hasServerChanges = false;

      // Update position if changed
      if (selectedPosition !== storedPosition) {
        updateData.position = selectedPosition;
        updateData.type = 'updateProfileData'; // Add type for profile data update
        localStorage.setItem('profilePosition', selectedPosition);
        hasServerChanges = true;
      }

      if (avatar !== storedAvatar) {
        updateData.avatar = avatar;
        updateData.type = 'updateProfilePicture'; // Add type for profile picture update
        hasServerChanges = true;
      }

      if (firstName !== profileData.firstName || lastName !== profileData.lastName) {
        updateData.firstName = firstName;
        updateData.lastName = lastName;
        updateData.type = 'updateProfileData'; // Reuse the same type for general profile data update
        hasServerChanges = true;
      }

      if (hasServerChanges) {
        const response = await fetch('https://www.ek0go8g.vudoo.ai/api/profile', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updateData),
        });

        const contentType = response.headers.get('Content-Type');
        let data;

        // Check if the response contains JSON
        if (contentType && contentType.includes('application/json')) {
          data = await response.json();
        } else {
          const text = await response.text();
          console.error('Server responded with non-JSON data:', text);
          throw new Error(`Unexpected response format: ${text}`);
        }

        if (response.ok) {
          const updatedProfile = { ...profileData };
          if (updateData.avatar) {
            updatedProfile.photoURL = data.photoURL;
            updatedProfile.avatar = data.photoURL;
            localStorage.setItem('profileAvatar', data.photoURL);
            setContextAvatar(data.photoURL);
          }
          if (updateData.firstName) updatedProfile.firstName = data.firstName;
          if (updateData.lastName) updatedProfile.lastName = data.lastName;
          if (updateData.position) updatedProfile.position = data.position;

          onUpdateProfile(updatedProfile);
          message.success('Profile updated successfully!');
        } else {
          console.error('Error updating profile:', data);
          alert(`Error updating profile: ${data.details || data.message || 'Unknown error'}`);
        }
      } else {
        message.info('No changes to save.');
      }
    } catch (error) {
      console.error('Error saving profile:', error);
      alert(`Error saving profile: ${error.message}`);
    } finally {
      setIsUploading(false);
    }
  };


  return (
    <div className="flex grow items-start gap-24 border-t border-white/15">
      <div className="flex flex-col gap-6 w-[500px]">
        <div className="py-4">
          <h2 className="text-white text-[15px]">Account data</h2>
        </div>

        <div className="flex items-center gap-5">
          <img className="w-16 h-16 rounded-full object-cover" src={avatar} alt="Profile" />
          <div className="flex flex-col gap-1.5">
            <button
              onClick={() => document.getElementById('fileInput').click()}
              className="self-start h-7 w-auto px-2 bg-[#d5d5d5] text-black text-sm rounded hover:opacity-80  transition-colors"
            >
              Upload new
            </button>
            <input
              type="file"
              id="fileInput"
              accept="image/png, image/jpeg"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
            <p className="text-white/50 text-[13px]">Min. 200x200 px. PNG or JPG</p>
          </div>
        </div>

        <InputField label="First name" value={profileData.firstName} />
        <InputField label="Last name" value={profileData.lastName} />
        <InputField
          label="Email"
          value={profileData.email}
          disabled={true}
          helperText={
            <span>
              To change your email, please contact{' '}
              <a href="mailto:support@vudoo.ai" className="text-blue-600 underline">
                support
              </a>
            </span>
          }
        />

        <div className="space-y-2">
          <label className="text-white text-sm">I mainly work/create as a ...</label>
          <select
            value={selectedPosition}
            onChange={(e) => setSelectedPosition(e.target.value)}
            className="w-full h-10 px-3 py-1 bg-white/10 rounded text-[15px] text-white"
          >
            <option value="" disabled>
              Choose one
            </option>
            {positions.map((position) => (
              <option key={position} value={position} className="bg-[#212121] text-white">
                {position}
              </option>
            ))}
          </select>
        </div>

        <div className="flex gap-2 pt-6">
          <button
            onClick={handleSave}
            className="px-3 py-2 bg-[#d5d5d5] text-black rounded hover:bg-[#c4c4c4] transition-colors hover:opacity-80"
          >
            Save
          </button>
          <button
            onClick={handleCancel}
            className="px-3 py-2 border border-white/20 text-[#d5d5d5] rounded hover:opacity-90  hover:text-white transition-colors"
          >
            Cancel
          </button>
        </div>
      </div>

      <div className="w-[400px] py-14 bg-[#212121] rounded-lg border border-white/20 flex flex-col justify-center items-start mt-18 px-6">
  {isLoadingPlan ? (
    <div className="text-center text-white text-lg font-normal w-full">
      Loading your plan...
    </div>
  ) : (
    <>
      {activePlan ? (
        <div className="text-center text-white text-lg font-normal w-full mt-4">
          You're on a {activePlan.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} subscription.
        </div>
      ) : (
        <div className="text-center text-white text-lg font-normal w-full mt-4">
          You're currently not a subscriber.
        </div>
      )}
      <div className="text-center text-white text-lg font-normal w-full mt-2">
        You have {userCredits} tokens left,
      </div>
      <div className="text-center text-white text-lg font-normal w-full mt-2">
        let's put them to work!
      </div>
    </>
  )}

  <div className="flex justify-center w-full mt-4">
    <button
      onClick={() => navigate("/home")}
      className="px-3 py-2 bg-[#d5d5d5] text-black rounded hover:bg-[#c4c4c4] transition-colors hover:opacity-80"
    >
      Go to Dashboard
    </button>
  </div>
</div>






    </div>
  );
};

AccountSection.propTypes = {
  profileData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    position: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
  userCredits: PropTypes.number.isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
};

export default AccountSection;