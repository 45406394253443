// src/components/UploadSection.js

import React from 'react';
import { Upload } from 'antd';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

const { Dragger } = Upload;

const UploadSection = ({
  pageTitle,
  instructionText,
  videoThumbnail,
  hoverBackground,
  handleModalOpen,
  handleFileUpload,
  isUploading,
  isModalVisible,
  lottieAnimation, // Pass the Lottie animation data as a prop
}) => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="w-full bg-[#151515] flex flex-col items-center">
      {/* Upload Area */}
      <div
        className="flex flex-col md:flex-row justify-between items-center gap-20 w-full max-w-[1120px] mx-auto p-10"
        style={{ minHeight: "calc(120vh - 295px)" }}
      >
        {/* Left Content */}
        <div className="w-full md:w-[400px] text-white flex flex-col gap-5">
          <h1 className="h2 font-normal text-left m-0">{pageTitle}</h1>
          <p className="text-16 opacity-60 mb-6">{instructionText}</p>
          <div
            className="w-40 h-24 rounded-lg border border-white/30 flex justify-center items-center cursor-pointer bg-[#212121] relative"
            onClick={handleModalOpen}
          >
            {!isModalVisible && (
              <>
                <img
                  src={videoThumbnail}
                  alt="Video Thumbnail"
                  className="w-full h-full rounded-lg absolute inset-0 object-cover"
                />
                <div className="w-10 h-10 bg-white/30 rounded-full backdrop-blur-sm drop-shadow-lg flex justify-center items-center z-10">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="white"
                  >
                    <path d="M2 12V0l10 6-10 6Z" />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Dragger Upload */}
        <Dragger
          name="file"
          accept="image/*"
          beforeUpload={() => false}
          onChange={handleFileUpload}
          className="border border-white/30 border-dashed w-full max-h-[500px] md:w-[600px] flex flex-col justify-items-stretch rounded-xl items-center bg-[#212121] cursor-pointer relative group"
          showUploadList={false}
        >
          <div
            className="rounded-xl hover-background absolute inset-0 bg-cover bg-center opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            style={{ backgroundImage: `url(${hoverBackground})` }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-0" />
          </div>

          <div className="h-96 w-full flex flex-col justify-center items-center relative z-10">
            {!isUploading ? (
              <>
                <div className="w-18 h-18 border border-white/30 rounded-full mb-6 flex justify-center items-center group-hover:bg-white/10 transition-colors duration-300">
                  <svg
                    width="24"
                    height="24"
                    stroke="white"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 3v9m0 9v-9m0 0h9m-9 0H3" />
                  </svg>
                </div>
                <p className="text-2xl text-white mb-2">Click here to upload image</p>
                <p className="text-white text-lg opacity-50">or drag and drop here</p>
              </>
            ) : (
              <Lottie options={lottieOptions} height={100} width={100} />
            )}
          </div>
        </Dragger>
      </div>
    </div>
  );
};

UploadSection.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  instructionText: PropTypes.string.isRequired,
  videoThumbnail: PropTypes.string.isRequired,
  hoverBackground: PropTypes.string.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  lottieAnimation: PropTypes.object.isRequired, // Prop for Lottie animation data
};

export default UploadSection;