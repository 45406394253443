import React from 'react';
import { motion } from 'framer-motion';

const MobileWarningPopup = ({ isVisible, closePopup }) => {
  const handleRedirect = () => {
    window.location.href = 'https://www.vudoo.ai/';
  };

  return (
    isVisible && (
      <motion.div
  initial={{ opacity: 0, y: -50 }}
  animate={{ opacity: 1, y: 0 }}
  exit={{ opacity: 0, y: -50 }}
  className="fixed inset-0 bg-black bg-opacity-10 flex items-center justify-center z-50 backdrop-blur-sm"
>
  <div className="bg-[#252525] rounded-lg p-8 shadow-xl max-w-[376px] w-full mx-4 text-center">
    <h2 className="text-2xl font-serif text-white mb-4">Welcome to Vudoo!</h2>
    <p className="text-gray-400 mb-2 text-left">
      Our full suite of AI-powered creative tools is currently optimized for
      desktop use only.
    </p>
    <p className="text-gray-400 mb-2 text-left">
      Our mobile version will be released soon.
    </p>
    <p className="text-gray-400 mb-8 text-left">Thanks for your interest.</p>

    <button
      onClick={handleRedirect}
      className="h-12 pr-2.5 bg-[#151515] rounded-lg border border-white/20 justify-center items-center gap-4 inline-flex w-full"
    >
      <div className="text-white text-base font-medium font-['Test Söhne'] leading-snug">
        Return to Home
      </div>
    </button>
  </div>
</motion.div>

    )
  );
};

export default MobileWarningPopup;
