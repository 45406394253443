// src/pages/UpscalerPage.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import Cookies from 'js-cookie';
import CustomButton from '../components/shared/CustomButton';
import Gallery from '../components/shared/Gallery';
import SettingsPanel from '../components/shared/SettingsPanel';
import ImageDisplay from '../components/shared/ImageDisplay';
import DownloadSection from '../components/shared/DownloadSection';
import SharedLayout from '../components/shared/SharedLayout';
import '../styles/UpscalerPage.css';
import usePreventRightClick from './usePreventRightClick';
import Hotjar from '@hotjar/browser';
import { useActionCard } from './ActionCardContext';
import ImageModal from './ImageModal';

// Define model options
const modelOptions = {
  Auto: 'juggernaut_reborn.safetensors [338b85bc4f]',
  Painting: 'impressionismOil_sd15.safetensors [84d76a0328]',
  Cinematic: 'consistentFactor_euclidV61.safetensors',
};

// Define default denoising_strength for endpoints
const endpointDefaultDenoising = {
  upscale: 0.2,
  'upscaler-highres': 0.155,
  'upscaler-portrait': 0.155,
};

// Conversion functions between denoising_strength and creativity
const denoisingToCreativity = (denoising_strength) =>
  Math.round((denoising_strength / 0.52) * 100);
const creativityToDenoising = (creativity) =>
  parseFloat(((creativity / 100) * 0.52).toFixed(2));

// Mapping function replicated from backend
const mapCompositionToResemblance = (composition) => {
  // Example mapping: linear scaling from 0-10 to 1-1.5
  const minComposition = 0;
  const maxComposition = 10;
  const minResemblance = 1;
  const maxResemblance = 1.5;

  // Clamp composition between min and max
  const clampedComposition = Math.min(
    Math.max(composition, minComposition),
    maxComposition
  );

  // Linear mapping
  const resemblance =
    ((clampedComposition - minComposition) /
      (maxComposition - minComposition)) *
      (maxResemblance - minResemblance) +
    minResemblance;

  return resemblance;
};

// Constants
const BASE_RESOLUTION = 1024 * 1024; // 1 MP
const MIN_TOKENS = 1; // Minimum token charge

// Default settings
const DEFAULTS = {
  creativity: 40, // Default for Creativity
  resemblance: 1, // Default for Composition
  hdrStrength: 1, // Default for HDR
};

const UpscalerPage = () => {
  usePreventRightClick();
  const navigate = useNavigate();
  const { selectedCard } = useActionCard();

  // State Variables
  const [scaleFactor, setScaleFactor] = useState(2);
  const [creativity, setCreativity] = useState(DEFAULTS.creativity);
  const [creativityEnabled, setCreativityEnabled] = useState(false);
  const [resemblance, setResemblance] = useState(DEFAULTS.resemblance);
  const [resemblanceEnabled, setResemblanceEnabled] = useState(false);
  const [hdrStrength, setHdrStrength] = useState(DEFAULTS.hdrStrength);
  const [hdrStrengthEnabled, setHdrStrengthEnabled] = useState(false);
  const [originalImageUrl, setOriginalImageUrl] = useState('');
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [upscaledImageUrl, setUpscaledImageUrl] = useState('');
  const [isUpscaled, setIsUpscaled] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [isUpscaling, setIsUpscaling] = useState(false);
  const [selectedModel, setSelectedModel] = useState('Auto');
  const [tokensAvailable, setTokensAvailable] = useState(null);
  const [tokensRequired, setTokensRequired] = useState(MIN_TOKENS);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [showInputVariants, setShowInputVariants] = useState(true);
  const [showDownloadSection, setShowDownloadSection] = useState(true);
  const [isDraggingEnabled, setIsDraggingEnabled] = useState(false);
  const [isDefault, setIsDefault] = useState(true);
  const [galleryImages, setGalleryImages] = useState([]);
  const [selectedImageState, setSelectedImageState] = useState('');
  const [imageMetadata, setImageMetadata] = useState([]);
  const [selectedMetadata, setSelectedMetadata] = useState({
    width: 0,
    height: 0,
    format: 'UNKNOWN',
    scaleFactor: 2,
    isProcessed: false,
    creativity: 40,
    composition: 1,
    hdrStrength: 1,
    optimizedFor: 'Auto',
  });
  const [nextPageToken, setNextPageToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeSession, setActiveSession] = useState(null);
  const [defaultCreativity, setDefaultCreativity] = useState(() => {
    const endpoint = selectedCard?.endpoint || 'upscale';
    return denoisingToCreativity(
      endpointDefaultDenoising[endpoint] || endpointDefaultDenoising['upscale']
    );
  });

  // New State Variable for Manual Adjustment
  const [isManuallyAdjusting, setIsManuallyAdjusting] = useState(false);

  // Initialize Hotjar
  useEffect(() => {
    Hotjar.init(
      process.env.REACT_APP_HOTJAR_ID,
      process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
    );
  }, []);

  // Function to deduct credits
  const deductCredits = async (creditsCost) => {
    try {
      const token = Cookies.get('token');
      if (!token) {
        throw new Error('User not authenticated');
      }

      const response = await fetch('https://www.ek0go8g.vudoo.ai/api/credits', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ action: 'deduct', creditsCost }),
      });

      if (!response.ok) {
        throw new Error('Failed to deduct credits');
      }

      const result = await response.json();
      return result.newCredits;
    } catch (error) {
      alert('Failed to deduct credits. Please try again.');
      throw error;
    }
  };

  // Function to fetch user profile
  const fetchUserProfile = useCallback(async () => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('https://www.ek0go8g.vudoo.ai/api/profile', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch user profile');

      const profileData = await response.json();
      setTokensAvailable(profileData.credits || 0);
    } catch (error) {
      navigate('/login');
    }
  }, [navigate]);

  // Function to update default creativity based on endpoint
  const updateDefaultCreativity = (endpoint) => {
    const denoising_strength = endpointDefaultDenoising[endpoint] || 0.2;
    const creativityValue = denoisingToCreativity(denoising_strength);
    console.log(
      `Updating defaultCreativity for endpoint "${endpoint}": ${creativityValue}`
    );
    setDefaultCreativity(creativityValue);

    // If creativity is disabled, set creativity to default
    if (!creativityEnabled) {
      setCreativity(creativityValue);
      setSelectedMetadata((prev) => ({
        ...prev,
        creativity: creativityValue,
      }));
      console.log(`Creativity disabled. Set to default: ${creativityValue}`);
    }
  };

  // Initial Image Loading Effect - Runs only once
  useEffect(() => {
    fetchUserProfile();

    const activeSessionData = sessionStorage.getItem('activeSession');

    if (activeSessionData) {
      const session = JSON.parse(activeSessionData);
      setOriginalImageUrl(session.originalImage.url);
      setUploadedImageUrl(session.originalImage.url);
      setImageDimensions({
        width: session.originalImage.width || 0,
        height: session.originalImage.height || 0,
      });

      // Create arrays for gallery images and metadata
      const variantUrls = [];
      const variantMetadata = [];

      // Add the original image as the first entry
      if (session.originalImage?.url) {
        variantUrls.push(session.originalImage.url);
        variantMetadata.push({
          width: session.originalImage.width || 0,
          height: session.originalImage.height || 0,
          format: session.originalImage.format || 'UNKNOWN',
          scaleFactor: 1, // Original image should have scale factor of 1
          isProcessed: false, // The original image is not processed
          creativity: 0,
          composition: 0,
          hdrStrength: 0,
          optimizedFor: 'Auto',
        });
      }

      // Add variants to the gallery, if they exist
      if (session.variants && Array.isArray(session.variants)) {
        session.variants.forEach((variant, index) => {
          if (variant.url) {
            // Only add to variants if it's not the original image
            if (variant.url !== session.originalImage.url) {
              variantUrls.push(variant.url);
              variantMetadata.push({
                width: variant.width || variant.finalWidth || 0,
                height: variant.height || variant.finalHeight || 0,
                format: variant.format || 'UNKNOWN',
                scaleFactor: variant.settings?.scaleFactor || 1, // Use the correct scale factor from variant settings
                isProcessed: true,
                creativity: variant.settings?.creativity || 0,
                composition: variant.settings?.resemblance || 0,
                hdrStrength: variant.settings?.hdrStrength || 0,
                optimizedFor: variant.settings?.model || 'Auto',
              });
            }
          }
        });
      }

      // Update the gallery images and metadata
      setGalleryImages(variantUrls);
      setImageMetadata(variantMetadata);

      // Set initial selected variant to the first item in the gallery
      if (variantUrls.length > 0) {
        setSelectedImageState(variantUrls[0]);
        setSelectedMetadata(variantMetadata[0]);
        setSelectedIndex(0);
      }

      // Set the active session
      setActiveSession(session);

      // Update settings based on session
      if (session.settings) {
        setScaleFactor(session.settings.scaleFactor || 2);

        // Ensure that creativity is set correctly
        if (session.settings.creativity > 0) {
          setCreativity(session.settings.creativity);
          setCreativityEnabled(true);
        } else {
          setCreativity(DEFAULTS.creativity);
          setCreativityEnabled(false);
        }

        // Ensure that resemblance is set correctly
        if (session.settings.resemblance > 0) {
          setResemblance(session.settings.resemblance);
          setResemblanceEnabled(true);
        } else {
          setResemblance(DEFAULTS.resemblance);
          setResemblanceEnabled(false);
        }

        // Ensure that hdrStrength is set correctly
        if (session.settings.hdrStrength > 0) {
          setHdrStrength(session.settings.hdrStrength);
          setHdrStrengthEnabled(true);
        } else {
          setHdrStrength(DEFAULTS.hdrStrength);
          setHdrStrengthEnabled(false);
        }

        setSelectedModel(session.settings.model || 'Auto');

        // Update defaultCreativity based on the endpoint associated with the model
        const endpoint = selectedCard?.endpoint || 'upscale';
        updateDefaultCreativity(endpoint);
      }

      // Remove 'activeSession' from sessionStorage after loading it to avoid reloading unintentionally
      sessionStorage.removeItem('activeSession');
    } else {
      // No active session, proceed with normal flow
      const imageUrl = sessionStorage.getItem('uploadedImageUrl');
      setOriginalImageUrl(imageUrl);
      setUploadedImageUrl(imageUrl);

      if (imageUrl) {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
          setImageDimensions({ width: img.width, height: img.height });
          const tokenCost = calculateTokensRequired(
            img.width,
            img.height,
            scaleFactor
          );
          setTokensRequired(tokenCost);
          console.log(`Tokens required set to: ${tokenCost}`);

          const metadata = {
            width: img.width,
            height: img.height,
            format:
              img.src.split('.').pop().split('?')[0].toUpperCase() ||
              'UNKNOWN',
            scaleFactor: scaleFactor,
            isProcessed: false,
            creativity: creativityEnabled ? creativity : 0,
            composition: resemblanceEnabled ? resemblance : 0,
            hdrStrength: hdrStrengthEnabled ? hdrStrength : 0,
            optimizedFor: selectedModel,
          };

          setImageMetadata([metadata]);
          setSelectedMetadata(metadata);
          setGalleryImages([imageUrl]);
          setSelectedImageState(imageUrl);
          setSelectedIndex(0);

          // Update settings state variables based on uploaded image
          setScaleFactor(metadata.scaleFactor || 2);

          // Ensure that creativity is set correctly
          if (metadata.creativity > 0) {
            setCreativity(metadata.creativity);
            setCreativityEnabled(true);
          } else {
            setCreativity(DEFAULTS.creativity);
            setCreativityEnabled(false);
          }

          // Ensure that resemblance is set correctly
          if (metadata.composition > 0) {
            setResemblance(metadata.composition);
            setResemblanceEnabled(true);
          } else {
            setResemblance(DEFAULTS.resemblance);
            setResemblanceEnabled(false);
          }

          // Ensure that hdrStrength is set correctly
          if (metadata.hdrStrength > 0) {
            setHdrStrength(metadata.hdrStrength);
            setHdrStrengthEnabled(true);
          } else {
            setHdrStrength(DEFAULTS.hdrStrength);
            setHdrStrengthEnabled(false);
          }

          setSelectedModel(metadata.optimizedFor || 'Auto');

          // Update defaultCreativity based on the endpoint
          const endpoint = selectedCard?.endpoint || 'upscale';
          updateDefaultCreativity(endpoint);
        };

        img.onerror = (error) => {
          console.error('Image failed to load:', error);
          message.error('Failed to load the uploaded image.');
        };
      }

      // Fetch images if no specific session is being continued
      fetchImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUserProfile, selectedCard?.endpoint]); // Added selectedCard.endpoint to dependencies

  // Separate useEffect for recalculating tokens when scaleFactor or imageDimensions change
  useEffect(() => {
    if (imageDimensions.width && imageDimensions.height) {
      const tokenCost = calculateTokensRequired(
        imageDimensions.width,
        imageDimensions.height,
        scaleFactor
      );
      setTokensRequired(tokenCost);
      console.log(`Tokens required updated to: ${tokenCost}`);
    }
  }, [scaleFactor, imageDimensions]);

  // Handle New Upscaled Image
  useEffect(() => {
    if (isUpscaled && upscaledImageUrl) {
      // Create metadata for the new image
      const newMetadata = {
        width: imageDimensions.width * scaleFactor,
        height: imageDimensions.height * scaleFactor,
        format: 'JPG',
        scaleFactor: scaleFactor,
        isProcessed: true,
        creativity: creativityEnabled ? creativity : 0,
        composition: resemblanceEnabled ? resemblance : 0,
        hdrStrength: hdrStrengthEnabled ? hdrStrength : 0,
        optimizedFor: selectedModel,
      };

      // Add the new image and metadata to the gallery
      setGalleryImages((prev) => [...prev, upscaledImageUrl]);
      setImageMetadata((prev) => [...prev, newMetadata]);

      // Select the new image
      const newIndex = galleryImages.length;
      setSelectedImageState(upscaledImageUrl);
      setSelectedMetadata(newMetadata);
      setSelectedIndex(newIndex);

      // Reset the upscaled flag
      setIsUpscaled(false);

      // Fetch user profile to update tokens
      fetchUserProfile();
    }
  }, [
    isUpscaled,
    upscaledImageUrl,
    imageDimensions,
    scaleFactor,
    creativityEnabled,
    creativity,
    resemblanceEnabled,
    resemblance,
    hdrStrengthEnabled,
    hdrStrength,
    selectedModel,
    fetchUserProfile,
    galleryImages.length,
  ]);

  // Function to calculate tokens required based on image dimensions and scale factor
  const calculateTokensRequired = (width, height, scaleFactor) => {
    if (width === 0 || height === 0) {
      console.error(
        'Width or Height is zero, cannot calculate tokens required.'
      );
      return MIN_TOKENS;
    }

    const scaledWidth = width * scaleFactor;
    const scaledHeight = height * scaleFactor;
    const scaledPixels = scaledWidth * scaledHeight;
    const scaledMegapixels = scaledPixels / 1_000_000;
    const tokenCost = Math.ceil(scaledMegapixels);

    console.log(
      `Calculated token cost: ${tokenCost} for ${scaledMegapixels} MP`
    );
    return Math.max(tokenCost, MIN_TOKENS);
  };

  // Function to handle toggle switches
  const handleToggle = (key, value) => {
    setIsManuallyAdjusting(true);
    console.log(`Toggle ${key}: ${value}`);

    switch (key) {
      case 'scaleFactor': {
        setScaleFactor(value);
        return;
      }

      case 'creativity': {
        setCreativityEnabled(value);
        if (value) {
          const currentValue = isManuallyAdjusting
            ? creativity
            : defaultCreativity;
          setCreativity(currentValue);
          setSelectedMetadata((prev) => ({
            ...prev,
            creativity: currentValue,
          }));
        } else {
          setCreativity(defaultCreativity);
          setSelectedMetadata((prev) => ({
            ...prev,
            creativity: defaultCreativity,
          }));
        }
        return;
      }

      case 'resemblance': {
        setResemblanceEnabled(value);
        if (value) {
          const currentValue = isManuallyAdjusting
            ? resemblance
            : DEFAULTS.resemblance;
          setResemblance(currentValue);
          setSelectedMetadata((prev) => ({
            ...prev,
            composition: currentValue,
          }));
        } else {
          setResemblance(DEFAULTS.resemblance);
          setSelectedMetadata((prev) => ({
            ...prev,
            composition: DEFAULTS.resemblance,
          }));
        }
        return;
      }

      case 'hdrStrength': {
        setHdrStrengthEnabled(value);
        if (value) {
          const currentValue = isManuallyAdjusting
            ? hdrStrength
            : DEFAULTS.hdrStrength;
          setHdrStrength(currentValue);
          setSelectedMetadata((prev) => ({
            ...prev,
            hdrStrength: currentValue,
          }));
        } else {
          setHdrStrength(DEFAULTS.hdrStrength);
          setSelectedMetadata((prev) => ({
            ...prev,
            hdrStrength: DEFAULTS.hdrStrength,
          }));
        }
        return;
      }

      default: {
        console.warn(`Unknown toggle key: ${key}`);
        return;
      }
    }
  };

  // Function to handle free slider movement
  const handleSettingsChange = (key, value) => {
    setIsManuallyAdjusting(true);
    console.log(`Settings change - ${key}: ${value}`);

    // Update the current settings state
    switch (key) {
      case 'creativity':
        setCreativity(value);
        setSelectedMetadata((prev) => ({
          ...prev,
          creativity: value,
        }));
        break;
      case 'resemblance':
        setResemblance(value);
        setSelectedMetadata((prev) => ({
          ...prev,
          composition: value,
        }));
        break;
      case 'hdrStrength':
        setHdrStrength(value);
        setSelectedMetadata((prev) => ({
          ...prev,
          hdrStrength: value,
        }));
        break;
      case 'scaleFactor':
        setScaleFactor(value);
        break;
      default:
        console.warn(`Unknown settings key: ${key}`);
        break;
    }
  };

  // Function to handle the upscale process
  const handleUpscale = async () => {
    setIsManuallyAdjusting(false);

    const token = Cookies.get('token');
    if (!token) {
      message.error('Please log in first.');
      navigate('/login');
      return;
    }

    if (tokensAvailable <= 1) {
      message.error('Not enough tokens to proceed. Please add more tokens.');
      return;
    }

    if (tokensAvailable < tokensRequired) {
      message.error('Not enough tokens. Please purchase more.');
      return;
    }

    setIsUpscaling(true);
    try {
      // Calculate backend values based on settings and endpoint
      const currentEndpoint = selectedCard?.endpoint || 'upscale';
      const creativityBackendValue = creativityEnabled
        ? creativityToDenoising(creativity)
        : endpointDefaultDenoising[currentEndpoint] || 0.2;
      const resemblanceBackendValue = resemblanceEnabled
        ? mapCompositionToResemblance(resemblance) // Use the mapping function
        : 1;

      const endpointURL = selectedCard?.endpoint
        ? `https://www.ek0go8g.vudoo.ai/api/${selectedCard.endpoint}`
        : 'https://www.ek0go8g.vudoo.ai/api/upscale';

      const payload = {
        input_image: originalImageUrl,
        scale_factor: scaleFactor,
        denoising_strength: creativityBackendValue,
        resemblance: resemblanceBackendValue,
        hdr_strength: hdrStrengthEnabled ? hdrStrength : 0,
        width: imageDimensions.width,
        height: imageDimensions.height,
        model: modelOptions[selectedModel],
        tokensSpent: tokensRequired,
      };

      console.log('Payload sent to upscale API:', payload);

      const response = await fetch(endpointURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new Error('Failed to upscale image');

      const result = await response.json();

      if (!result.upscaledImage) {
        throw new Error('Upscaled image not returned from API');
      }

      setUpscaledImageUrl(result.upscaledImage);
      setIsUpscaled(true);
      message.success('Image upscaled successfully!');

      await deductCredits(tokensRequired);
      console.log('Tokens deducted:', tokensRequired);
    } catch (error) {
      console.error('Upscaling failed:', error);
      message.error(`Upscaling failed: ${error.message}`);
    } finally {
      setIsUpscaling(false);
    }
  };

  // Function to handle image downloads
  const handleDownload = (format) => {
    if (!selectedImageState) {
      console.error('No image selected for download.');
      message.error(
        'No image selected for download. Please select a variant.'
      );
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = selectedImageState;

    img.onload = () => {
      const { width, height } = selectedMetadata;
      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `vudoo_${width}x${height}.${format}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
            message.success(`Downloading ${format.toUpperCase()} file...`);
            console.log(
              `Downloading ${format.toUpperCase()} file: vudoo_${width}x${height}.${format}`
            );
          } else {
            message.error('Failed to create an image for download.');
            console.error('Canvas toBlob returned null.');
          }
        },
        `image/${format}`
      );
    };

    img.onerror = () => {
      console.error('Failed to load image for download');
      message.error('Download failed. Please try again.');
    };
  };

  // Function to handle zoom changes
  const handleZoomChange = (direction) => {
    setZoomLevel((prevZoom) => {
      const newZoom =
        direction === 'in'
          ? Math.min(prevZoom + 10, 300)
          : Math.max(prevZoom - 10, 50);
      console.log(`Zoom level changed to: ${newZoom}%`);
      return newZoom;
    });
  };

  // Function to fetch user upscaled images
  const fetchImages = async (pageToken = null) => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
      return;
    }

    setIsLoading(true);

    try {
      const url = new URL(
        'https://www.ek0go8g.vudoo.ai/api/getUserUpscaledImages'
      );
      const params = {
        limit: 20,
      };
      if (pageToken) {
        params.lastVisible = pageToken;
      }
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch images');
      }

      const data = await response.json();

      if (!data.sessions || !Array.isArray(data.sessions)) {
        console.error('API response does not contain sessions array:', data);
        throw new Error('Invalid data format received from API');
      }

      // Extract images and metadata from sessions
      const allImages = [];
      const allMetadata = [];

      data.sessions.forEach((session) => {
        // Handle original image
        if (session.originalImage) {
          allImages.push(session.originalImage.url);
          allMetadata.push({
            width: session.originalImage.width || 0,
            height: session.originalImage.height || 0,
            format: session.originalImage.format || 'UNKNOWN',
            scaleFactor: 1, // Original image always has scale factor 1
            isProcessed: false,
            creativity: 0,
            composition: 0,
            hdrStrength: 0,
            optimizedFor: 'Auto',
          });
        }

        // Handle variants
        if (session.variants && Array.isArray(session.variants)) {
          session.variants.forEach((variant) => {
            if (variant.url) {
              // Make sure variant has a URL
              allImages.push(variant.url);

              // Create metadata with proper default values
              const metadata = {
                width: variant.width || variant.finalWidth || 0,
                height: variant.height || variant.finalHeight || 0,
                format: variant.format || 'UNKNOWN',
                scaleFactor: session.settings?.scaleFactor || 1,
                isProcessed: true,
                creativity: session.settings?.creativity || 0,
                composition: session.settings?.resemblance || 0,
                hdrStrength: session.settings?.hdrStrength || 0,
                optimizedFor: session.settings?.model || 'Auto',
              };

              allMetadata.push(metadata);
            }
          });
        }
      });

      // Update state with new images and metadata
      setGalleryImages((prevImages) => {
        const uniqueImages = [...new Set([...prevImages, ...allImages])];
        console.log(
          `Gallery images updated. Total images: ${uniqueImages.length}`
        );
        return uniqueImages;
      });

      setImageMetadata((prevMetadata) => {
        const newMetadata = [...prevMetadata, ...allMetadata];
        console.log(
          `Image metadata updated. Total metadata entries: ${newMetadata.length}`
        );
        return newMetadata;
      });
      setNextPageToken(data.nextPageToken || null);

      console.log(`Fetched ${allImages.length} new images.`);
    } catch (error) {
      console.error('Error fetching images:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle loading more images
  const handleLoadMore = () => {
    if (nextPageToken) {
      fetchImages(nextPageToken);
    }
  };

  // Updated handleThumbnailClick Function
  const handleThumbnailClick = (imageUrl, metadata, index) => {
    console.log('Thumbnail clicked:', imageUrl, metadata);
    const img = new Image();
    img.src = imageUrl;

    // Preload the new image before updating the state
    img.onload = () => {
      setSelectedImageState(imageUrl);
      setSelectedIndex(index);

      // Always update slider positions and settings when switching variants
      setScaleFactor(metadata.scaleFactor > 0 ? metadata.scaleFactor : 2);

      // Updated logic to prevent setting 0
      setCreativity(metadata.creativity > 0 ? metadata.creativity : defaultCreativity);
      setCreativityEnabled(metadata.creativity > 0);

      setResemblance(metadata.composition > 0 ? metadata.composition : DEFAULTS.resemblance);
      setResemblanceEnabled(metadata.composition > 0);

      setHdrStrength(metadata.hdrStrength > 0 ? metadata.hdrStrength : DEFAULTS.hdrStrength);
      setHdrStrengthEnabled(metadata.hdrStrength > 0);

      setSelectedModel(metadata.optimizedFor || 'Auto');

      // Update selected metadata for display only
      setSelectedMetadata({
        ...metadata,
        creativity: metadata.creativity > 0 ? metadata.creativity : defaultCreativity,
        composition: metadata.composition > 0 ? metadata.composition : DEFAULTS.resemblance,
        hdrStrength: metadata.hdrStrength > 0 ? metadata.hdrStrength : DEFAULTS.hdrStrength,
      });

      // Reset manual adjustment flag when switching variants
      setIsManuallyAdjusting(false);

      console.log('State updated with new selection:', {
        imageUrl,
        metadata,
        index,
      });
    };

    img.onerror = () => {
      console.error('Failed to load image for display.');
    };
  };

  // Function to handle model changes
  const handleModelChange = (value) => {
    // Set Manual Adjustment Flag
    setIsManuallyAdjusting(true);
    setSelectedModel(value);
    console.log('Selected Model changed to:', value);

    // Update defaultCreativity based on the new model's endpoint
    const endpoint = selectedCard?.endpoint || 'upscale';
    updateDefaultCreativity(endpoint);
  };

  // Function to toggle dragging
  const toggleDragging = () => {
    setIsDraggingEnabled((prev) => !prev);
    console.log(`Dragging enabled: ${!isDraggingEnabled}`);
  };

  // Function to handle modal closure
  const handleModalClose = (selectedVariant) => {
    setIsModalOpen(false);
    if (selectedVariant) {
      const index = galleryImages.findIndex(
        (url) => url === selectedVariant.url
      );
      if (index !== -1) {
        handleThumbnailClick(
          galleryImages[index],
          imageMetadata[index],
          index
        );

        console.log('Modal closed. Selected variant settings updated.');
      }
    }
  };

  // Function to handle continuing with the project
  const handleContinueWithProject = () => {
    navigate('/some-path'); // Replace with actual path
    handleModalClose();
  };

  // Props for DownloadSection
  const downloadSectionProps = {
    handleDownload,
    outputResolution: `${selectedMetadata.width} x ${selectedMetadata.height}`,
    isCollapsed: !showDownloadSection,
    toggleCollapse: () => setShowDownloadSection(!showDownloadSection),
  };

  // Props for header
  const headerProps = {
    newAction: 'New Upscale',
    newActionPath: '/upload?type=upscaler',
  };

  // Sidebar content including Gallery and SettingsPanel
  const sidebarContent = (
    <>
      <Gallery
        images={galleryImages}
        imageMetadata={imageMetadata}
        onThumbnailClick={handleThumbnailClick}
        isCollapsed={!showInputVariants}
        toggleCollapse={() => setShowInputVariants(!showInputVariants)}
        isUpscalePage={true}
      />
      <SettingsPanel
        settings={{
          scaleFactor,
          outputResolution: `${selectedMetadata.width} x ${selectedMetadata.height}`,
          tokensAvailable,
          tokensRequired,
          optimizedFor: selectedModel, // Ensure this is included
          onModelChange: handleModelChange,
          defaultCreativity,
          options: [
            {
              label: 'Creativity',
              key: 'creativity',
              enabled: creativityEnabled,
              min: 0,
              max: 100,
              step: 1,
              value: creativity,
              labelLeft: '0',
              labelRight: '100',
              tooltip: `Backend Value: ${
                creativityEnabled
                  ? creativityToDenoising(creativity)
                  : creativityToDenoising(defaultCreativity)
              }`,
            },
            {
              label: 'Composition',
              key: 'resemblance',
              enabled: resemblanceEnabled,
              min: 0,
              max: 10,
              step: 1,
              value: resemblance,
              labelLeft: 'Strict',
              labelRight: 'Hallucinate',
            },
            {
              label: 'HDR',
              key: 'hdrStrength',
              enabled: hdrStrengthEnabled,
              min: 0,
              max: 10,
              step: 1,
              value: hdrStrength,
              labelLeft: '0',
              labelRight: '10',
            },
          ],
          actionButton: (
            <CustomButton
              onClick={handleUpscale} // Use the updated handleUpscale function
              disabled={isUpscaling}
            >
              {isUpscaling ? 'Upscaling...' : 'Upscale'}
            </CustomButton>
          ),
        }}
        onToggle={handleToggle} // Updated handler
        onChange={handleSettingsChange} // Updated handler to allow free slider movement
        isUpscalePage={true}
        isDefault={isDefault}
        currentEndpoint={selectedCard?.endpoint || 'upscale'}
      />

      {/** 
       * **Conditional Rendering Based on Processed Status**
       * Render DownloadSection only if the selected image has been processed.
       */}
      {selectedMetadata.isProcessed && (
        <DownloadSection {...downloadSectionProps} />
      )}

      {nextPageToken && (
        <div className="flex justify-center mt-4">
          <CustomButton onClick={handleLoadMore} loading={isLoading}>
            Load More
          </CustomButton>
        </div>
      )}
    </>
  );

  // Props for zoom controls
  const zoomControlsProps = {
    zoomLevel,
    onZoomIn: () => setZoomLevel((prev) => Math.min(prev + 10, 300)),
    onZoomOut: () => setZoomLevel((prev) => Math.max(prev - 10, 50)),
    onResetZoom: () => setZoomLevel(100),
    isDraggingEnabled,
    onToggleDragging: toggleDragging,
  };

  return (
    <div>
      <ImageModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        session={activeSession}
        onContinue={handleContinueWithProject}
      />
      <SharedLayout
        headerProps={headerProps}
        sidebarContent={sidebarContent}
        zoomControlsProps={zoomControlsProps}
      >
        <ImageDisplay
          key="image-display" // Use a static key or a more stable identifier
          uploadedImageUrl={originalImageUrl}
          selectedImageUrl={selectedImageState}
          isProcessing={isUpscaling}
          isProcessed={selectedMetadata.isProcessed}
          zoomLevel={zoomLevel}
          onResetZoom={zoomControlsProps.onResetZoom}
          isDraggingEnabled={isDraggingEnabled}
          upscaledImageUrl={upscaledImageUrl}
          processType="upscaling"
        />
      </SharedLayout>
    </div>
  );
};

export default UpscalerPage;
