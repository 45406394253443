// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ActionCardGridTransitions.css */

.card-enter {
    opacity: 0;
    transform: translateX(50%);
  }
  .card-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  }
  
  .card-exit {
    position: relative;
    opacity: 1;
    transform: translateX(0);
  }
  .card-exit-active {
    position: absolute; /* Prevents layout shift */
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/ActionCardGridTransitions.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;IACI,UAAU;IACV,0BAA0B;EAC5B;EACA;IACE,UAAU;IACV,wBAAwB;IACxB,kEAAkE;EACpE;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,wBAAwB;EAC1B;EACA;IACE,kBAAkB,EAAE,0BAA0B;IAC9C,UAAU;IACV,4BAA4B;IAC5B,kEAAkE;EACpE","sourcesContent":["/* ActionCardGridTransitions.css */\r\n\r\n.card-enter {\r\n    opacity: 0;\r\n    transform: translateX(50%);\r\n  }\r\n  .card-enter-active {\r\n    opacity: 1;\r\n    transform: translateX(0);\r\n    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;\r\n  }\r\n  \r\n  .card-exit {\r\n    position: relative;\r\n    opacity: 1;\r\n    transform: translateX(0);\r\n  }\r\n  .card-exit-active {\r\n    position: absolute; /* Prevents layout shift */\r\n    opacity: 0;\r\n    transform: translateX(-100%);\r\n    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
