// src/components/LibraryLearnSection.js

import React, { useState, useEffect, useRef } from "react";
import GalleryItem from "../components/sharedCards/GalleryItem";
import ImageModal from "../components/ImageModal";
import { useNavigate } from "react-router-dom";
import article1Thumbnail from "./Assets/learn-section-placeholder-01.jpg";
import video1Thumbnail from "./Assets/learn-section-placeholder-02.jpg";
import article2Thumbnail from "./Assets/learn-section-placeholder-03.jpg";

const LibraryLearnSection = ({
  sessions,
  onSessionClick,
  onContinueWithProject,
  onExpandChange,
  scrollThreshold = 50, // Added scrollThreshold prop with default value
}) => {
  const [activeTab, setActiveTab] = useState("library");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const libraryRef = useRef(null);
  const navigate = useNavigate();

  const learnItems = [
    {
      id: 1,
      title: "Learn about Image Restoration",
      type: "Article",
      thumbnailUrl: article1Thumbnail,
      url: "/learn/image-restoration",
    },
    {
      id: 2,
      title: "Upscaling Basics",
      type: "Video",
      thumbnailUrl: video1Thumbnail,
      url: "/learn/upscaling-basics",
    },
    {
      id: 3,
      title: "Advanced Techniques",
      type: "Article",
      thumbnailUrl: article2Thumbnail,
      url: "/learn/advanced-techniques",
    },
  ];

  const handleLearnItemClick = (url) => {
    navigate(url);
  };

  const handleSessionClick = (session) => {
    setSelectedSession(session);
    setIsModalOpen(true);
    if (onSessionClick) {
      onSessionClick(session);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSession(null);
  };

  const handleContinueWithProject = (session) => {
    if (session) {
      sessionStorage.setItem("sessionData", JSON.stringify(session));
      navigate("/upscaler");
    }
  };

  // Create a shallow copy before sorting to avoid mutating props
  const sortedSessions = [...sessions].sort((a, b) => {
    const aLastVariantDate = new Date(
      a.variants[a.variants.length - 1]?.createdAt || 0
    );
    const bLastVariantDate = new Date(
      b.variants[b.variants.length - 1]?.createdAt || 0
    );
    return bLastVariantDate - aLastVariantDate;
  });

  const distributeSessions = (sessions) => {
    const columns = [[], [], [], []];
    sessions.forEach((session, index) => {
      columns[index % 4].push(session);
    });
    return columns;
  };

  const sessionColumns = distributeSessions(sortedSessions);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = libraryRef.current.scrollTop;
  
      if (!isExpanded && scrollTop > scrollThreshold) {
        setIsExpanded(true);
        document.body.style.overflow = "hidden"; // Lock body scroll when expanded
        if (onExpandChange) onExpandChange(true);
      } else if (isExpanded && scrollTop <= scrollThreshold) {
        setIsExpanded(false);
        document.body.style.overflow = ""; // Restore body scroll when collapsed
        if (onExpandChange) onExpandChange(false);
      }
    };
  
    const libraryElement = libraryRef.current;
  
    // Dynamically adjust padding when expanded
    const adjustPadding = () => {
      if (isExpanded) {
        const firstRowHeight =
          libraryElement.querySelector(".grid-cols-4 > div:first-child")
            ?.offsetHeight || 0;
        libraryElement.style.paddingTop = `${firstRowHeight}px`;
      } else {
        libraryElement.style.paddingTop = "10px"; // Default collapsed padding
      }
    };
  
    if (libraryElement) {
      libraryElement.addEventListener("scroll", handleScroll);
      adjustPadding(); // Initial adjustment
    }
  
    return () => {
      if (libraryElement) {
        libraryElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isExpanded, onExpandChange, scrollThreshold]);
  
  

  return (
    <div
      ref={libraryRef}
      className={`transition-all duration-25 ease-in-out fixed bottom-0 ${
        isExpanded ? "h-full z-50" : "h-[10vh]"
      } overflow-y-auto bg-[var(--coal-900)]`}
      style={{
        width: "calc(100% - 160px)",
        left: "50%",
        transform: "translateX(-50%)",
        maxWidth: "1700px",
        paddingTop: isExpanded ? "0" : "10px"
      }}
    >
<div className="h-px bg-white/10" />

<div className="flex">
  <div className="w-[300px] text-white">
    <div className="flex flex-col gap-2 w-75">
      <div className="flex space-x-4">
        <button
          className={`px-1 pt-2 border-t-2 ${
            activeTab === "library" ? "border-white" : "opacity-50 border-transparent"
          }`}
          onClick={() => setActiveTab("library")}
          aria-label="My Library Tab"
        >
          My Library
        </button>
        <button
          className={`px-1 pt-2 ${
            activeTab === "learn" ? "opacity-100" : "opacity-50"
          }`}
          onClick={() => setActiveTab("learn")}
          aria-label="Learn Tab"
        >
          Learn
        </button>
      </div>
      <div className="opacity-50 text-sm">
        {activeTab === "library"
          ? "Manage and view your processed images"
          : "Explore articles and tutorials about image processing"}
      </div>
    </div>
  </div>


        <div className="flex-1 pt-4">
          {activeTab === "library" ? (
            sortedSessions.length > 0 ? (
              <div className="grid grid-cols-4 gap-3">
                {sessionColumns.map((column, columnIndex) => (
                  <div key={columnIndex} className="flex flex-col gap-3">
                    {column.map((session) => {
                      // Filter out sessions that have only the original image
                      const nonOriginalVariants = session.variants.filter(
                        (variant) => variant.tool !== "original"
                      );
                      if (nonOriginalVariants.length === 0) {
                        return null;
                      }

                      // Get the latest variant from the session
                      const latestVariant =
                        nonOriginalVariants[nonOriginalVariants.length - 1];

                      return (
                        <GalleryItem
                          key={session.sessionId}
                          image={{
                            id: session.sessionId,
                            url: latestVariant.url,
                            format: latestVariant.format,
                            uploadDate:
                              latestVariant.createdAt || session.uploadDate, // Prioritize latestVariant.createdAt
                            tool: latestVariant.tool,
                            settings: session.settings,
                          }}
                          onClick={() => handleSessionClick(session)}
                          className="rounded-lg overflow-hidden hover:shadow-lg transition-shadow"
                        />
                      );
                    })}
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center text-gray-400">
                No upscaled image sessions found. Start by upscaling your first
                image!
              </div>
            )
          ) : (
            <div className="grid grid-cols-3 gap-4">
              {learnItems.map((item) => (
                <div
                  key={item.id}
                  className="bg-[#2C2C2C] rounded-lg cursor-pointer hover:bg-[#3C3C3C] transition-colors duration-500"
                  onClick={() => handleLearnItemClick(item.url)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleLearnItemClick(item.url);
                    }
                  }}
                  aria-label={`Navigate to ${item.title}`}
                >
                  <img
                    src={item.thumbnailUrl || "/placeholder.svg"}
                    alt={item.title}
                    className="w-full h-40 object-cover rounded-t-lg"
                  />
                  <div className="p-4">
                    <div className="text-white text-lg font-semibold mb-2">
                      {item.title}
                    </div>
                    <div className="text-white opacity-50">{item.type}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <ImageModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        session={selectedSession}
        onContinue={handleContinueWithProject}
      />
    </div>
  );
};

export default LibraryLearnSection;
