import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import actionCards from '../ActionCardsData';
import { useActionCard } from '../ActionCardContext';

const Header = ({ newAction, newActionPath }) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { selectedCard, setSelectedCard } = useActionCard();
  const dropdownRef = useRef(null);
  const dropdownContentRef = useRef(null);
  const arrowRef = useRef(null);

  const filteredCards = actionCards.filter((card) =>
    card.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Only close if clicking outside both the arrow and dropdown content
      if (
        dropdownRef.current && 
        !dropdownRef.current.contains(event.target) &&
        (!dropdownContentRef.current || !dropdownContentRef.current.contains(event.target)) &&
        (!arrowRef.current || !arrowRef.current.contains(event.target))
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleStartNewClick = () => {
    if (selectedCard.type === 'restoration') {
      navigate('/upload?type=restoration&endpoint=/api/imageRestoration');
    } else {
      navigate(`/upload?type=${selectedCard.type}&endpoint=${selectedCard.endpoint}`);
    }
  };

  return (
    <header className="flex justify-between items-center fixed left-4 top-4 z-10 w-full">
      <div className="flex items-center gap-2">
        {/* Home Button */}
        <div
          className="w-10 h-10 bg-[#212121] rounded-lg flex items-center justify-center border border-white/20 cursor-pointer hover:bg-[#2c2c2c] transition-colors duration-300"
          onClick={() => navigate('/home')}
          role="button"
          tabIndex={0}
          aria-label="Home"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              navigate('/home');
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            className="transition-colors duration-800"
            aria-hidden="true"
          >
            <path
              stroke="var(--white)"
              strokeWidth="1.5"
              d="M2.415 8.711a2.25 2.25 0 0 1 1.024-1.886l5.333-3.467a2.25 2.25 0 0 1 2.453 0l5.333 3.467a2.25 2.25 0 0 1 1.024 1.886v6.79c0 .69-.56 1.25-1.25 1.25h-3.667a.25.25 0 0 1-.25-.25v-3a1.75 1.75 0 0 0-1.75-1.75H9.332a1.75 1.75 0 0 0-1.75 1.75v3a.25.25 0 0 1-.25.25H3.665c-.69 0-1.25-.56-1.25-1.25V8.71Z"
            />
          </svg>
        </div>

        {/* Start New Button and Dropdown Container */}
        <div className="relative" ref={dropdownRef}>
          <div
            className="h-10 pl-4 pr-3 bg-[#212121] rounded-lg border border-white/20 flex items-center gap-3 hover:bg-[#2c2c2c] transition-colors duration-300"
          >
            {/* Start New Button */}
            <div 
              className="text-white text-base font-normal font-['Test Söhne'] leading-snug flex-1 cursor-pointer"
              onClick={handleStartNewClick}
              role="button"
              tabIndex={0}
              aria-label="Start new action"
              onKeyPress={(e) => {
                if (e.key === "Enter") handleStartNewClick();
              }}
            >
              Start new
            </div>

            {selectedCard.type !== 'restoration' && (
              <div
                ref={arrowRef}
                className="flex items-center cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropdown(!showDropdown);
                }}
                onMouseEnter={() => setShowDropdown(true)}
                role="button"
                tabIndex={0}
                aria-label="Toggle dropdown"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.stopPropagation();
                    setShowDropdown(!showDropdown);
                  }
                }}
              >
                {/* Divider */}
                <div className="w-px h-10 bg-[#424242]" />
                
                {/* Arrow Container */}
                <div className="flex items-center justify-center ml-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className={`transition-transform duration-300 text-white/50 ${showDropdown ? 'rotate-180' : ''}`}
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>

          {/* Dropdown Menu */}
          {showDropdown && selectedCard.type !== 'restoration' && (
            <div 
              ref={dropdownContentRef}
              className="w-60 px-2 py-1.5 bg-[#212121] rounded-lg border border-white/20 flex-col justify-start items-start gap-1 inline-flex absolute left-0 mt-2 transition-all duration-300 ease-in-out overflow-y-auto transform"
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
            >
              {/* Search Input */}
              <div className="self-stretch p-2 rounded justify-start items-center inline-flex">
                <div className="relative flex items-center w-full">
                  <div className="w-4 h-4 absolute left-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-white/50"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-4.35-4.35M11 19a8 8 0 100-16 8 8 0 000 16z"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    placeholder="Search styles"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="bg-transparent pl-8 pr-2 text-white/50 text-sm font-normal font-['Test Söhne'] leading-tight focus:outline-none w-full"
                    aria-label="Search styles"
                  />
                </div>
              </div>

              {/* Divider */}
              <div className="self-stretch h-[9px] px-2 py-1 flex-col justify-start items-start gap-2.5 flex">
                <div className="h-px relative">
                  <div className="w-52 h-px left-0 top-0 absolute bg-white/20" />
                </div>
              </div>

              {/* Dropdown Items */}
              {filteredCards.length > 0 ? (
                filteredCards.map((card) => (
                  <div
                    key={card.id}
                    className="self-stretch px-2 py-1.5 rounded justify-between items-center inline-flex cursor-pointer hover:bg-[#2c2c2c] transition-colors duration-300"
                    onClick={() => {
                      setSelectedCard(card);
                      setShowDropdown(false);
                      navigate(`/upload?type=${card.type}&endpoint=${card.endpoint}`);
                    }}
                    role="button"
                    tabIndex={0}
                    aria-label={`Navigate to ${card.title}`}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        setSelectedCard(card);
                        setShowDropdown(false);
                        navigate(`/upload?type=${card.type}&endpoint=${card.endpoint}`);
                      }
                    }}
                  >
                    <div className="text-white text-sm font-normal font-['Test Söhne'] leading-tight">
                      {card.title}
                    </div>
                    {card.betaBadge && (
                      <div className="px-1.5 py-0.5 bg-[#ffcc68] rounded justify-center items-center flex">
                        <div className="text-black text-[10px] font-bold uppercase leading-[14px] tracking-wide">
                          Beta
                        </div>
                      </div>
                    )}
                    {card.newBadge && (
                      <div className="px-1.5 py-0.5 bg-[#5AF4A6] rounded justify-center items-center flex">
                        <div className="text-black text-[10px] font-bold uppercase leading-[14px] tracking-wide">
                          New
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="self-stretch px-2 py-1.5 rounded justify-start items-center inline-flex">
                  <div className="text-white/50 text-sm font-normal font-['Test Söhne'] leading-tight">
                    No matching styles found
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  newAction: PropTypes.string.isRequired,
  newActionPath: PropTypes.string.isRequired,
};

export default Header;
