// src/pages/LandingPage.js

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Lottie from "react-lottie";
import animationData from "../components/Assets/vudoo-logo-animation.json";
import ZoomControls from "../components/shared/ZoomControls";
import ImageModal from "../components/ImageModal";
import "../styles/LandingPage.css";
import "../styles/LibraryLearnSection.css";

import Hotjar from "@hotjar/browser";
import { onIdTokenChanged } from "firebase/auth";
import { auth } from "../firebase";
import LandingPageMobile from "./LandingPageMobile";
import ActionCardsGrid from "../components/ActionCardsGrid";
import LibraryLearnSection from "./LibraryLearnSection";
import { message } from "antd";
import debounce from "lodash.debounce"; // Import debounce

const LandingPage = () => {
  const navigate = useNavigate();
  const librarySectionRef = useRef(null);
  const actionCardsGridRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    position: "",
    avatar: "/placeholder.svg?height=64&width=64",
  });
  const [userCredits, setUserCredits] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);

  // New state to track if LibraryLearnSection is expanded
  const [isLibraryExpanded, setIsLibraryExpanded] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Define the adjustSpacing function
  const adjustSpacing = () => {
    if (librarySectionRef.current) {
      const screenHeight = Math.max(window.innerHeight, 800); // Minimum height
      const minMargin = 20; // Adjust for design
      const maxMargin = 150; // Adjust for design
      const calculatedMargin =
        minMargin + ((screenHeight - 800) / 800) * (maxMargin - minMargin);
      const clampedMargin = Math.max(minMargin, Math.min(maxMargin, calculatedMargin));
      librarySectionRef.current.style.marginTop = `${clampedMargin}px`;
    }
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsMobile(window.innerWidth <= 768);
      adjustSpacing();
    }, 100); // Debounce to optimize performance

    // Initial spacing adjustment
    adjustSpacing();

    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Initialize Hotjar
  useEffect(() => {
    Hotjar.init(
      process.env.REACT_APP_HOTJAR_ID,
      process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
    );
  }, []);


// Add a scroll handler for the LibraryLearnSection
useEffect(() => {
  const handlePageScroll = () => {
    if (librarySectionRef.current) {
      // Calculate the scrollable height of the page
      const pageHeight = document.documentElement.scrollHeight - window.innerHeight;

      // Calculate the scrollable height of the library
      const libraryHeight =
        librarySectionRef.current.scrollHeight - librarySectionRef.current.clientHeight;

      // Map the page scroll position to the library scroll position
      const scrollRatio = window.scrollY / pageHeight;
      librarySectionRef.current.scrollTop = scrollRatio * libraryHeight;
    }
  };

  // Attach scroll event listener
  window.addEventListener("scroll", handlePageScroll);

  // Clean up the listener on component unmount
  return () => {
    window.removeEventListener("scroll", handlePageScroll);
  };
}, []);



  
  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const idToken = await user.getIdToken(true);
        Cookies.set("token", idToken, { expires: 1, path: "/" });
        setIsLoggedIn(true);
        await fetchUserProfile();
        await fetchSessions();
      } else {
        setIsLoggedIn(false);
        Cookies.remove("token");
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const refreshUserProfile = async () => {
      try {
        await fetchUserProfile();
      } catch (error) {
        navigate("/login");
      }
    };

    const intervalId = setInterval(refreshUserProfile, 30 * 60 * 1000); // Refresh every 30 minutes

    return () => clearInterval(intervalId);
  }, [navigate]);

  const fetchUserProfile = async () => {
    const token = Cookies.get("token");
    if (!token) {
      setIsLoggedIn(false);
      setLoading(false);
      navigate("/login");
      return;
    }

    try {
      const response = await fetch("https://www.ek0go8g.vudoo.ai/api/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch user profile");

      const data = await response.json();
      setProfileData({
        name: data.name || "User",
        email: data.email || "",
        position: data.position || "",
        avatar: data.photoURL || "/placeholder.svg?height=64&width=64",
      });

      setUserCredits(data.credits || 0);
      setIsLoggedIn(true);
      setLoading(false);
    } catch (error) {
      setIsLoggedIn(false);
      setLoading(false);
      navigate("/login");
    }
  };

  const fetchSessions = async () => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const [restoredResponse, upscaledResponse] = await Promise.all([
        fetch("https://www.ek0go8g.vudoo.ai/api/imageRestoreGallery", {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }),
        fetch("https://www.ek0go8g.vudoo.ai/api/imageUpscaleGallery", {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      if (!restoredResponse.ok || !upscaledResponse.ok) {
        throw new Error("Failed to fetch images");
      }

      const [restoredData, upscaledData] = await Promise.all([
        restoredResponse.json(),
        upscaledResponse.json(),
      ]);

      // Process restored images into sessions format
      const restoredSessions = (restoredData.images || []).map((img) => ({
        sessionId: img.id || String(Date.now()),
        originalImage: { url: img.originalUrl || img.url, format: img.format },
        variants: [
          { url: img.originalUrl || img.url, format: img.format },
          { url: img.url, format: img.format },
        ],
        settings: {
          model: img.model || "default",
          hdrStrength: img.hdrStrength,
        },
        uploadDate: img.uploadedAt || img.createdAt,
        type: "restore",
      }));

      // Combine with upscaled sessions
      const allSessions = [
        ...restoredSessions,
        ...(upscaledData.sessions || []),
      ].sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));

      setSessions(allSessions);
    } catch (error) {
      message.error("Failed to fetch image sessions");
    }
  };

  const handleSessionClick = (session) => {
    setSelectedSession(session);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSession(null);
  };

  const handleContinueWithProject = (session) => {
    // Store the full session data in sessionStorage
    sessionStorage.setItem("selectedSession", JSON.stringify(session));

    // Navigate to the appropriate page based on session type
    if (session.type === "restore") {
      navigate("/restore");
    } else {
      navigate("/upscaler");
    }
  };

  const firstName = profileData.name.split(" ")[0];

  return (
    <div className="min-h-screen bg-[var(--coal-900)] text-white p-4 pt-20">
      {isMobile ? (
        <LandingPageMobile />
      ) : (
        <>
          {/* Header with conditional classes */}
          <header
            className={`fixed top-0 left-0 right-0 flex justify-between items-center h-16 px-4 z-50 bg-[var(--coal-900)] transition-transform duration-300 ${
              isLibraryExpanded ? "-translate-y-full" : "translate-y-0"
            }`}
          >
            <div className="flex items-center">
              <button
                className="flex items-center focus:outline-none relative z-50"
                onClick={() => navigate("/home")}
                style={{ backgroundColor: "transparent", border: "none" }}
                aria-label="Go to Home"
              >
                <Lottie options={defaultOptions} height={16} width={"auto"} />
              </button>
              <div className="text-white/30 text-[15px] font-medium h1 leading-tight px-4">//</div>
              <div className="text-white/30 text-[15px] font-medium h1 leading-tight px-2">
                Upscale & Enhance
              </div>
            </div>

            <div
              className="px-3 py-2 rounded-lg border border-[var(--color-border)] bg-[var(--coal-800)] text-sm cursor-pointer hover:bg-[#2c2c2c] transition-colors duration-300 relative z-50"
              onClick={() => navigate(isLoggedIn ? "/profile" : "/login")}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  navigate(isLoggedIn ? "/profile" : "/login");
                }
              }}
              aria-label={isLoggedIn ? "Go to Profile" : "Go to Login"}
            >
              {userCredits !== null ? `${userCredits} tokens left` : "Fetching..."}
            </div>
          </header>

          {/* Action Cards Grid */}
          <div
            ref={actionCardsGridRef}
            className={`flex flex-col mt-20 mb-16 max-w-[1120px] mx-auto transition-opacity duration-300 ${
              isLibraryExpanded ? "opacity-0 pointer-events-none mb-0" : "opacity-100 mb-16"
            }`}
          >
            <ActionCardsGrid firstName={firstName} />
          </div>

          {/* Library Learn Section with Dynamic Spacing */}
          <div
            ref={librarySectionRef}
            className="library-section spacing-container"
            // Ensure no conflicting margin styles in CSS
          >
            <LibraryLearnSection
              sessions={sessions}
              onSessionClick={handleSessionClick}
              onContinueWithProject={handleContinueWithProject}
              onExpandChange={setIsLibraryExpanded} 
              scrollThreshold={10} // Custom threshold value
              // Pass the handler
            />
          </div>

          {/* Zoom Controls */}
          <ZoomControls zoomLevel={100} onZoomIn={() => {}} onZoomOut={() => {}} />

          {/* Image Modal */}
          <ImageModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            session={selectedSession}
            onContinue={handleContinueWithProject}
          />
        </>
      )}
    </div>
  );
};

export default LandingPage;
