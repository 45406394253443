import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import videoFile from '../Assets/button-video-verlay-01-small.mp4';

// Video background component for the button
const VideoBackground = ({ className }) => (
  <video
    className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-500 ${className}`}
    src={videoFile}
    autoPlay
    loop
    muted
    playsInline
  />
);

const CustomButton = ({ children, onClick, disabled, type, className, notEnoughTokens, ...props }) => {
  const buttonClass = classNames(
    'w-full py-3 rounded-lg transition-all duration-1000 ease-in-out border relative overflow-hidden',
    {
      // Styles when the button is disabled
      'bg-gradient-to-r from-buttonDisabledStart to-buttonDisabledEnd cursor-not-allowed border-white/15': disabled,

      // Styles when not enough tokens
      'bg-[#ffcc68] text-black font-semibold cursor-pointer border border-black hover:opacity-85 ease-out duration-300': notEnoughTokens && !disabled,

      // Default styles when button is enabled
      'hover:border-[#d5d5d5] border-white/15 hover:bg-opacity-75': !disabled && !notEnoughTokens,
    },
    className
  );

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      disabled={disabled}
      type={type || 'button'}
      {...props}
    >
      {/* Conditionally render the video background if the button is not disabled and there are enough tokens */}
      {!disabled && !notEnoughTokens && <VideoBackground className="rounded-lg opacity-75 hover:opacity-100" />}

      {/* Content of the button (children) */}
      <span className={`relative z-10 ${!notEnoughTokens ? 'mix-blend-exclusion' : ''}`}>{children}</span>
    </button>
  );
};

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  notEnoughTokens: PropTypes.bool,
};

CustomButton.defaultProps = {
  disabled: false,
  notEnoughTokens: false,
};

export default CustomButton;
