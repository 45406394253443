import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/ActionCard.css';
import '../../styles/globals.css';

const ActionCard = ({
  title,
  description,
  buttonText,
  onButtonClick,
  hoverBackgroundImage,
  comingSoon,
  betaBadge,
  newBadge,
  isSuggestionCard,
}) => {
  return (
    <div
      className="w-[332px] h-[415px] relative rounded cursor-pointer overflow-hidden border border-[#333] bg-[#212121] group"
      onClick={!comingSoon ? onButtonClick : null} // Prevent click if comingSoon
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter" && !comingSoon) {
          onButtonClick();
        }
      }}
      aria-label={comingSoon ? "Coming Soon" : `Perform action: ${title}`}
    >
      {isSuggestionCard ? (
        // Suggestion Card Content
        <div className="flex flex-col items-center justify-center w-full h-full px-4 text-center">
          {/* Title */}
          <h3 className="text-white/50 text-18 mb-2">
            Are we missing a model?
          </h3>

          {/* Subtitle with line break */}
          <p className="text-white/50 text-14 font-normal mb-6">
            Please help us by suggesting a
            <br />
            model that you need.
          </p>




          {/* Button */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              onButtonClick();
            }}
            className="badge4 flex items-center gap-2 px-4 py-2 bg-[#444] text-white text-sm font-medium rounded hover:bg-[#555] text-[14px] transition-colors duration-300 ease-in-out"
            aria-label="Suggest on Discord"
          >
            {buttonText}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-white"
            >
              <path d="M7 17L17 7m0 0v10m0-10H7" />
            </svg>
          </button>
        </div>
      ) : (
        // Regular Action Card Content
        <>
          {/* Hover Background Image */}
          {hoverBackgroundImage && (
            <img
              src={hoverBackgroundImage} // Use the hoverBackgroundImage directly
              alt={`${title} Background`}
              className="w-full h-full left-0 top-0 absolute object-cover transition-transform duration-500 ease-in-out group-hover:scale-[1.1]"
            />
          )}

          {/* Gradient Overlay */}
          <div className="w-full h-1/3 left-0 bottom-0 absolute bg-gradient-to-t from-[#151515] via-[#151515] to-transparent opacity-90 transition-opacity duration-500 ease-in-out group-hover:opacity-70" />

          {/* Content Wrapper */}
          <div className="absolute bottom-6 left-4 right-4 z-10 flex flex-col gap-2">
            {/* Title and Beta Badge */}
            <div className="flex items-center gap-2 mb-2 ">
              <h2 className="text-white text-2xl font-semibold  text-24 leading-tight">
                {title}
              </h2>
              {betaBadge && (
                <span className="text-xs font-bold text-[10px] leading-[14px]  mb-2 text-black bg-[#ffcc68] px-1.5 py-0.5 rounded">
                  BETA
                </span>
              )}
              {newBadge && (
                <span className="text-xs font-bold text-[10px] leading-[14px] mb-2 text-black bg-[#5AF4A6] px-1.5 py-0.5 rounded">
                  NEW
                </span>
              )}
            </div>


            {/* Description */}
            <p className="text-white text-sm  text-12_2  opacity-80 leading-tight">
              {description}
            </p>
          </div>

          {/* Arrow Icon - Animates from above to the right on hover */}
          <div className="absolute top-[-60px] right-4 w-12 h-12 p-3 bg-[#151515]/60 rounded-full flex justify-center items-center gap-2.5 z-10 group-hover:top-4 group-hover:transition-all group-hover:duration-500 ease-in-out">
            <div
              className="flex items-center justify-center w-full h-full text-white transition-all duration-300 ease-in-out"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M7 17L17 7m0 0v10m0-10H7" />
              </svg>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

ActionCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string, // Optional for suggestion cards
  onButtonClick: PropTypes.func, // Optional for coming soon or suggestion cards
  hoverBackgroundImage: PropTypes.string, // Optional image prop
  comingSoon: PropTypes.bool, // Flag to indicate if the feature is coming soon
  betaBadge: PropTypes.bool,
  newBadge: PropTypes.bool,
  isSuggestionCard: PropTypes.bool, // Flag for special suggestion card behavior
};

ActionCard.defaultProps = {
  buttonText: '',
  onButtonClick: () => { },
  hoverBackgroundImage: '',
  comingSoon: false,
  betaBadge: false,
  newBadge: false,
  isSuggestionCard: false,
};

export default ActionCard;
