import { useState, useEffect } from "react";

const useVisibleRows = (rowHeight = 450, gap = 16) => {
  const [visibleRows, setVisibleRows] = useState(1);

  const calculateVisibleRows = () => {
    const windowHeight = window.innerHeight;
    // Calculate the number of rows that can fit in the window height
    const rows = Math.floor((windowHeight - 100) / (rowHeight + gap)); // Subtracting 100px for padding/margins
    setVisibleRows(rows > 0 ? rows : 1);
  };

  useEffect(() => {
    calculateVisibleRows();
    window.addEventListener("resize", calculateVisibleRows);
    return () => {
      window.removeEventListener("resize", calculateVisibleRows);
    };
  }, [rowHeight, gap]);

  return visibleRows;
};

export default useVisibleRows;
