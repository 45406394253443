import React, { useState, useCallback  } from 'react';
import { ReactComponent as InfoIcon } from '../Assets/info.svg';
import TooltipCard from './TooltipCard';
import { getTokenTooltipData } from './TooltipData';
import { getStripe } from '../../utils/stripe'; // Stripe utility
import { getAuth } from 'firebase/auth';
import '../../styles/globals.css';

const TokenPurchaseCard = ({
  packName,
  tokens,
  price,
  description,
  hoverBackgroundImage,
  hoverBackgroundPosition,
  priceId, // The Stripe price ID
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const tooltipData = getTokenTooltipData(packName);

  const handleCheckout = useCallback(async () => {
    if (isLoading) return; // Prevent multiple clicks
    setIsLoading(true);
    try {
      console.log(
        `Initiating checkout for priceId: ${priceId} in pack: ${packName}`
      );

      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        alert("You need to be logged in to proceed with checkout.");
        return;
      }

      const idToken = await user.getIdToken();

      const response = await fetch(
        "https://www.ek0go8g.vudoo.ai/api/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            priceId,
            mode: "payment",
            success_url: `${window.location.origin}/profile?session_id={CHECKOUT_SESSION_ID}&success=true`,
            cancel_url: `${window.location.origin}/profile`,
          }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to create checkout session: ${response.statusText}`
        );
      }

      const { sessionId } = await response.json();
      const stripe = await getStripe();

      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error("Error during checkout:", error);
      alert("Checkout failed. Please try again.");
    } finally {
      // Optionally add a short delay before re-enabling the button
      setIsLoading(false);
    }
  }, [isLoading, priceId, packName]);

  // Handle keyboard events for accessibility
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        handleCheckout();
      }
    },
    [handleCheckout]
  );

  return (
    <div
      className={`w-[230px] h-[124px] px-3 pt-3 pb-4 bg-[#212121] rounded-lg flex-col justify-between items-start inline-flex relative group overflow-visible cursor-pointer ${
        isLoading ? "opacity-50 pointer-events-none" : ""
      }`}
      onClick={handleCheckout}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      aria-label={`Purchase ${packName} pack for $${price}`}
    >
      {/* Info Icon at the top-right corner */}
      <div
        className="absolute top-2 right-2 z-30"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={(e) => e.stopPropagation()} // Prevent click from triggering handleCheckout
        onKeyDown={(e) => e.stopPropagation()} // Prevent keyboard events from triggering handleCheckout
        role="button"
        tabIndex={0}
        aria-label="More information"
        onKeyPress={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            // Optionally handle InfoIcon keyboard interactions here
          }
        }}
      >
        <InfoIcon className="w-4 h-4 text-white opacity-80 cursor-pointer hover:opacity-100 transition-opacity duration-200" />
      </div>

      {/* Smooth Tooltip Card Display */}
      <div
        className={`absolute top-10 right-2 z-40 w-[260px] transition-all duration-300 ${
          showTooltip
            ? "opacity-100 transform translate-y-0"
            : "opacity-0 transform -translate-y-4 pointer-events-none"
        }`}
      >
        <TooltipCard title="Estimated token spend" items={tooltipData} />
      </div>

      {/* Hover Background Image */}
      {hoverBackgroundImage && (
        <div
          className="absolute inset-0 bg-cover bg-center opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          style={{
            backgroundImage: `url(${hoverBackgroundImage})`,
            backgroundPosition: hoverBackgroundPosition,
          }}
        >
          {/* Semi-transparent overlay to ensure text readability */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        </div>
      )}

      {/* Content */}
      <div className="flex-col justify-between items-end gap-3 flex relative z-10">
        <div className="self-stretch flex-col justify-start items-start gap-4 flex">
          <div className="flex-col justify-center items-start flex">
            <div className="text-white text-14 leading-tight">{packName}</div>
            <div>
              <span className="text-white h3-sans leading-relaxed">
                {tokens}
              </span>
              <span className="text-white text-13 leading-none"> tokens</span>
            </div>
          </div>
        </div>

        <div className="self-stretch justify-end items-center gap-3 inline-flex relative z-10">
          <div className="grow shrink basis-0 text-white/50 text-xs font-normal leading-none">
            {description}
          </div>
          <div className="flex-col justify-start items-end gap-2.5 inline-flex">
            {/* Purchase Button */}
            <button
              onClick={(e) => {
                e.stopPropagation(); // Prevent click from bubbling up to the card
                handleCheckout();
              }}
              disabled={isLoading}
              className={`h-8 px-3 rounded border border-white/20 text-[#d5d5d5] text-15-m 
    hover:bg-[#d5d5d5] hover:border-[#d5d5d5] hover:text-black hover:opacity-90 
    transition-all duration-300 group-hover:bg-[#d5d5d5] 
    group-hover:border-[#d5d5d5] group-hover:text-black 
    ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              {isLoading ? "Processing..." : `Get for $${price}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenPurchaseCard;
