// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import UploadPage from './components/UploadPage';
import UpscalerPage from './components/UpscalerPage';
import LoginPage from './components/LoginPage';
import ProfilePage from './components/ProfilePage';
import ImageRestorationPage from './components/ImageRestorationPage';
import SuccessPage from './components/SuccessPage';
import '../src/styles/globals.css';
import Cookies from 'js-cookie';
import { ProfileProvider } from './ProfileContext'; // Import ProfileProvider
import { ActionCardProvider } from './components/ActionCardContext'; // Import ActionCardProvider

// ProtectedRoute Component
const ProtectedRoute = ({ children }) => {
  const token = Cookies.get('token'); // Retrieve token from cookie
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const App = () => {
  return (
    <ProfileProvider>
      <ActionCardProvider> {/* Wrap the app with ActionCardProvider */}
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<LoginPage />} />
            <Route path="/home" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/success" element={<SuccessPage />} />

            {/* Protected Routes */}
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/upload"
              element={
                <ProtectedRoute>
                  <UploadPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/upscaler"
              element={
                <ProtectedRoute>
                  <UpscalerPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/restoration"
              element={
                <ProtectedRoute>
                  <ImageRestorationPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </ActionCardProvider>
    </ProfileProvider>
  );
};

export default App;
