import React, { useState, useEffect } from 'react';

const ColorToggleButton = () => {
  const [circleColor, setCircleColor] = useState('#151515'); // Initial circle color

  // Function to update circle color based on current circle color
  const updateCircleColor = () => {
    switch (circleColor.toLowerCase()) {
      case '#151515':
        setCircleColor('#454545');
        break;
      case '#454545':
        setCircleColor('#b8b8b8');
        break;
      case '#b8b8b8':
        setCircleColor('#ffffff');
        break;
      case '#ffffff':
        setCircleColor('#151515');
        break;
      default:
        setCircleColor('#151515'); // Fallback in case of any unexpected color
    }
  };

  // Automatically update the circle color once on mount
  useEffect(() => {
    updateCircleColor();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const toggleBackgroundColor = () => {
    // Apply a smooth transition to the body's background color
    document.body.style.transition = 'background-color 0.5s ease-in-out';

    // Apply the current circle color to the body background
    document.body.style.backgroundColor = circleColor;

    // Update the circle color based on its current color
    updateCircleColor();
  };

  return (
    <button
      aria-label="Toggle Background Color"
      className="w-5 h-5 rounded-full border border-borderColor flex justify-center items-center"
      onClick={toggleBackgroundColor}
      style={{
        backgroundColor: circleColor,
        transition: 'background-color 0.5s ease-in-out', // Smooth transition for the button's background color
      }}
    />
  );
};

export default ColorToggleButton;