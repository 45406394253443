import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import Cookies from 'js-cookie';
import Lottie from 'react-lottie';
import animationData from '../components/Assets/vudoo-logo-animation.json';
import background from '../components/Assets/background.png';
import Hotjar from '@hotjar/browser';

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  // Lottie animation options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // Hotjar Integration
  useEffect(() => {
    Hotjar.init(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
  
    if (sessionId) {
      // Perform session verification using the verify-checkout-session API
      const verifyCheckoutSession = async () => {
        const token = Cookies.get('token');
        try {
          // Step 1: Call verify-checkout-session to validate and update credits/active plan
          const response = await fetch('https://www.ek0go8g.vudoo.ai/api/verify-checkout-session', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ sessionId }), // Pass sessionId for verification
          });
  
          if (!response.ok) throw new Error('Failed to verify checkout session');
  
          const data = await response.json();
          console.log(`Verification successful. Active plan: ${data.planTitle}, Credits added: ${data.creditsAdded}`);
  
          // Set loading to false and navigate to profile
          setLoading(false);
          navigate('/profile');
        } catch (error) {
          console.error('Error verifying checkout session:', error);
          // If an error occurs, we can decide how to handle it, for example, navigate to an error page or display an error message.
          setLoading(false);
        }
      };
  
      verifyCheckoutSession();
    } else {
      // No sessionId provided, directly set loading to false
      setLoading(false);
    }
  }, [location.search, navigate]);
  
  return (
    <div
      className="min-h-screen flex items-center justify-center bg-[#0A0A0A] bg-cover bg-center"
      style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    >
      {/* Logo Animation in the Top-Left corner */}
      <header className="absolute top-4 left-4 flex items-center cursor-pointer" onClick={() => navigate('/')}>
        <Lottie options={defaultOptions} height={24} width="auto" />
      </header>

      {/* Main Content */}
      <div className="w-full max-w-[376px]">
        <div className="bg-[#151515] rounded-lg p-8 shadow-xl transition-all duration-300">
          <h2 className="text-2xl font-serif text-white mb-4">Transaction in Progress</h2>
          <p className="text-gray-400 mb-8">
            Please wait while we process your transaction. You will be redirected to your profile shortly.
          </p>

          {/* Loading Spinner */}
          {loading ? (
            <div className="flex justify-center items-center mb-8">
              <Spin size="large" />
            </div>
          ) : (
            // Navigation Button
            <button
              onClick={() => navigate('/profile')}
              className="h-12 pr-2.5 bg-[#151515] rounded-lg border border-white/20 justify-center items-center gap-4 inline-flex w-full"
            >
              <div className="text-white text-base font-medium">Go to Profile</div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
