import React, { useState, useEffect, useRef } from 'react';
import ImageSlider from '../ImageSlider';
import Lottie from 'react-lottie';
import lottieAnimation from '../Assets/loading-animation.json';
import PropTypes from 'prop-types';
import '../../styles/globals.css';

const ImageDisplay = ({
  uploadedImageUrl,
  selectedImageUrl,
  isProcessing,
  isProcessed,
  zoomLevel = 100,
  onZoomIn,
  onZoomOut,
  isDraggingEnabled,
  upscaledImageUrl,
  showBadges = true,
  variants = [],
  processType = null,
}) => {
  const [imageDimensions, setImageDimensions] = useState({ width: 968, height: 544 });
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });
  const [screenDimensions, setScreenDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const dragStart = useRef({ x: 0, y: 0 });
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const lastPosition = useRef({ x: 0, y: 0 });

  const scale = zoomLevel / 100;

  useEffect(() => {
    const updateScreenDimensions = () => {
      setScreenDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', updateScreenDimensions);
    return () => window.removeEventListener('resize', updateScreenDimensions);
  }, []);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieAnimation,
    rendererSettings: {
      preserveAspectRatio: 'none',
    },
  };

  useEffect(() => {
    const updateContainerDimensions = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        setContainerDimensions({ width: clientWidth, height: clientHeight });
      }
    };

    updateContainerDimensions();
    window.addEventListener('resize', updateContainerDimensions);
    return () => window.removeEventListener('resize', updateContainerDimensions);
  }, []);

  useEffect(() => {
    if (uploadedImageUrl) {
      const img = new Image();
      img.src = uploadedImageUrl;

      img.onload = () => {
        const containerAspectRatio = containerDimensions.width / containerDimensions.height;
        const imageAspectRatio = img.width / img.height;

        let newWidth, newHeight;

        if (imageAspectRatio > containerAspectRatio) {
          newWidth = containerDimensions.width;
          newHeight = newWidth / imageAspectRatio;
        } else {
          newHeight = containerDimensions.height;
          newWidth = newHeight * imageAspectRatio;
        }

        setImageDimensions({ width: newWidth, height: newHeight });
        setPosition({ x: 0, y: 0 });
        lastPosition.current = { x: 0, y: 0 };
        setIsImageLoading(false);
      };
      img.onerror = () => setIsImageLoading(false);
    }
  }, [uploadedImageUrl, containerDimensions]);

  useEffect(() => {
    if (variants.length > 0 && !selectedVariant) {
      setSelectedVariant(variants[0]);
    }
  }, [variants, selectedVariant]);

  const constrainPosition = (pos) => {
    if (!imageRef.current) return pos;

    const rect = imageRef.current.getBoundingClientRect();
    const buffer = 50; // Minimum pixels of image that must remain visible

    const bounds = {
      left: -rect.width + buffer,
      right: window.innerWidth - buffer,
      top: -rect.height + buffer,
      bottom: window.innerHeight - buffer,
    };

    const rawX = rect.left + pos.x - lastPosition.current.x;
    const rawY = rect.top + pos.y - lastPosition.current.y;

    let newX = pos.x;
    let newY = pos.y;

    if (rawX < bounds.left) {
      newX = bounds.left - rect.left + lastPosition.current.x;
    }
    if (rawX > bounds.right) {
      newX = bounds.right - rect.left + lastPosition.current.x;
    }
    if (rawY < bounds.top) {
      newY = bounds.top - rect.top + lastPosition.current.y;
    }
    if (rawY > bounds.bottom) {
      newY = bounds.bottom - rect.top + lastPosition.current.y;
    }

    lastPosition.current = { x: newX, y: newY };
    return { x: newX, y: newY };
  };

  const handleMouseDown = (e) => {
    if (!isDraggingEnabled) return;
    e.preventDefault();
    setIsDragging(true);
    dragStart.current = {
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    };
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !isDraggingEnabled) return;

    const newPosition = {
      x: e.clientX - dragStart.current.x,
      y: e.clientY - dragStart.current.y,
    };

    const constrainedPosition = constrainPosition(newPosition);
    setPosition(constrainedPosition);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (e) => {
    if (!isDraggingEnabled) return;
    e.preventDefault();
    setIsDragging(true);
    const touch = e.touches[0];
    dragStart.current = {
      x: touch.clientX - position.x,
      y: touch.clientY - position.y,
    };
  };

  const handleTouchMove = (e) => {
    if (!isDragging || !isDraggingEnabled) return;
    e.preventDefault();

    const touch = e.touches[0];
    const newPosition = {
      x: touch.clientX - dragStart.current.x,
      y: touch.clientY - position.y,
    };

    const constrainedPosition = constrainPosition(newPosition);
    setPosition(constrainedPosition);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('touchmove', handleTouchMove, { passive: false });
      window.addEventListener('touchend', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [isDragging, isDraggingEnabled]);

  useEffect(() => {
    if (zoomLevel === 100) {
      const resetPosition = { x: 0, y: 0 };
      setPosition(resetPosition);
      lastPosition.current = resetPosition;
    }
  }, [zoomLevel]);

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex-1 flex justify-center items-center relative">
        <div
          ref={containerRef}
          className="relative overflow-visible flex justify-center items-center"
          style={{
            width: '100%',
            height: 'calc(100vh - 160px)',
            cursor: isDraggingEnabled ? (isDragging ? 'grabbing' : 'grab') : 'default',
          }}
        >
          <div
            ref={imageRef}
            style={{
              width: `${imageDimensions.width}px`,
              height: `${imageDimensions.height}px`,
              transform: `scale(${scale}) translate(${position.x / scale}px, ${position.y / scale}px)`,
              transformOrigin: 'center',
              transition: isDragging ? 'none' : 'transform 0.3s ease-out',
              position: 'absolute',
              zIndex: 20,
            }}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            className="will-change-transform"
          >
            <div className="relative w-full h-full flex justify-center items-center">
              <img
                src={uploadedImageUrl}
                alt="Original"
                className="object-contain w-full h-full"
                draggable="false"
              />

              {showBadges && isProcessed && !isProcessing && !isDraggingEnabled && !isImageLoading && (
                <>
                  <div
                    className="badge absolute bg-black/50 text-white px-2 py-1 rounded text-sm"
                    style={{
                      bottom: '10px',
                      left: '10px',
                      zIndex: 15,
                    }}
                  >
                    BEFORE
                  </div>
                  <div
                    className="badge absolute bg-black/50 text-white px-2 py-1 rounded text-sm"
                    style={{
                      bottom: '10px',
                      right: '10px',
                      zIndex: 15,
                    }}
                  >
                    {selectedVariant?.name || 'AFTER'}
                  </div>
                </>
              )}
            </div>

            {isProcessed && !isImageLoading && !isDraggingEnabled && (
              <ImageSlider
                originalUrl={uploadedImageUrl}
                comparisonUrl={selectedVariant?.imageUrl || selectedImageUrl || upscaledImageUrl}
                uploadedImageWidth={imageDimensions.width}
                uploadedImageHeight={imageDimensions.height}
                scale={scale}
                containerRef={imageRef}
              />
            )}
          </div>

          {isProcessing && (
            <div
              className="absolute flex flex-col justify-center items-center"
              style={{
                width: `${imageDimensions.width * scale + 40}px`, // Adding more width to avoid clipping
                height: `${imageDimensions.height * scale + 40}px`, // Adding more height to avoid clipping
                transform: `translate(${position.x}px, ${position.y}px)`,
                zIndex: 25,
                overflow: 'visible',
                padding: '20px',
                backdropFilter: `blur(25px)`,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 'auto',
                  height: 'auto',
                  padding: '10px', // Adding padding to give enough space to avoid clipping
                }}
              >
                <Lottie
                  options={lottieOptions}
                  height={77}
                  width={77}
                  style={{
                    overflow: 'visible',
                    marginBottom: '10px', // Adding some margin to avoid overlap with text
                  }}
                />
                {processType && (
                  <div className="text-center text-white text-[32px] h2 text-lg font-normal">
                    {processType === 'upscaling' ? 'Upscaling...' : 'Restoring...'}
                  </div>
                )}
              </div>
            </div>
          )}

        </div>
      </div>

      {variants.length > 0 && (
        <div className="flex justify-center mt-4 space-x-2">
          {variants.map((variant, index) => (
            <button
              key={index}
              onClick={() => setSelectedVariant(variant)}
              className={`px-4 py-2 rounded ${selectedVariant === variant ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'
                }`}
            >
              {variant.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

ImageDisplay.propTypes = {
  uploadedImageUrl: PropTypes.string.isRequired,
  selectedImageUrl: PropTypes.string,
  isProcessing: PropTypes.bool.isRequired,
  isProcessed: PropTypes.bool.isRequired,
  zoomLevel: PropTypes.number.isRequired,
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  isDraggingEnabled: PropTypes.bool.isRequired,
  upscaledImageUrl: PropTypes.string,
  showBadges: PropTypes.bool,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    })
  ),
  processType: PropTypes.oneOf(['upscaling', 'restoring']),
};

export default ImageDisplay;