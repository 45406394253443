// src/context/ActionCardContext.js

import React, { createContext, useState, useContext } from 'react';
import actionCards from './ActionCardsData'; // Import initial action cards data

// Create the context
const ActionCardContext = createContext();

// Create the provider component
export const ActionCardProvider = ({ children }) => {
  // Set up state for the selected card, defaulting to the first card
  const [selectedCard, setSelectedCard] = useState(actionCards[0]);

  // Provider value includes both selectedCard and a function to update it
  return (
    <ActionCardContext.Provider value={{ selectedCard, setSelectedCard }}>
      {children}
    </ActionCardContext.Provider>
  );
};

// Create a custom hook to easily use the ActionCardContext
export const useActionCard = () => {
  return useContext(ActionCardContext);
};
