// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/GalleryItem.css */

.gallery-item {
  align-self: start;
  height: auto;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.7rem;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gallery-item-image {
  width: 100%;
  object-fit: contain; /* Maintain aspect ratio, fit within container */
}

.gallery-item-overlay {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: var(--coal-900);
  color: var(--color-w-70);
  padding: 2px 6px;
  border: 1px solid var(--color-border);;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;   
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .gallery-item-overlay {
  opacity: 1;
}

`, "",{"version":3,"sources":["webpack://./src/styles/GalleryItem.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;EACE,iBAAiB;EACjB,YAAY;EACZ,2CAA2C;EAC3C,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,mBAAmB,EAAE,gDAAgD;AACvE;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,iCAAiC;EACjC,wBAAwB;EACxB,gBAAgB;EAChB,qCAAqC;EACrC,kBAAkB;EAClB,gCAAgC;EAChC,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;EACzB,qBAAqB;EACrB,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE,UAAU;AACZ","sourcesContent":["/* src/styles/GalleryItem.css */\r\n\r\n.gallery-item {\r\n  align-self: start;\r\n  height: auto;\r\n  border: 1px solid rgba(255, 255, 255, 0.15);\r\n  border-radius: 0.7rem;\r\n  overflow: hidden;\r\n  position: relative;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n}\r\n\r\n.gallery-item-image {\r\n  width: 100%;\r\n  object-fit: contain; /* Maintain aspect ratio, fit within container */\r\n}\r\n\r\n.gallery-item-overlay {\r\n  position: absolute;\r\n  top: 8px;\r\n  right: 8px;\r\n  background-color: var(--coal-900);\r\n  color: var(--color-w-70);\r\n  padding: 2px 6px;\r\n  border: 1px solid var(--color-border);;\r\n  border-radius: 4px;\r\n  font-family: 'Inter', sans-serif;\r\n  font-size: 10px;\r\n  font-weight: 600;\r\n  line-height: 14px;\r\n  text-transform: uppercase;\r\n  letter-spacing: 0.5px;   \r\n  opacity: 0;\r\n  transition: opacity 0.3s ease;\r\n}\r\n\r\n.gallery-item:hover .gallery-item-overlay {\r\n  opacity: 1;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
