import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';

const UsageHistoryRow = ({ item }) => {
  // Track hover state
  const [isHovered, setIsHovered] = useState(false);

  // Skip rendering rows where jobId is missing
  if (!item.jobId) {
    return null; // Do not render anything for entries without jobId
  }

  // Function to download the image as PNG using Blob
  const downloadImage = async () => {
    try {
      const response = await fetch(item.thumbnailUrl);
      if (!response.ok) {
        console.error('Failed to fetch image');
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      // Create a temporary link element to initiate the download
      const link = document.createElement('a');
      link.href = url;
      link.download = `${item.jobId}.png`; // Filename for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Clean up the URL object

    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  return (
    <tr className="h-12 text-14">
      {/* Download Icon and Thumbnail */}
      <div className="w-28">
        <td className="max-h-7 py-2 flex">
          {/* Download Button with Hover Effect */}
          <div
            onClick={downloadImage}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`mr-5 h-7 w-7 flex items-center justify-center cursor-pointer rounded ${
              isHovered ? 'bg-[#2c2c2c] border border-white/20' : 'button-xs button-secondary'
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16"
              className="transition-all"
            >
              <path
                stroke={isHovered ? '#ffffff' : 'var(--color-w-70)'} // Change stroke color on hover
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M2 10v1.333a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V10M8 1.333V10m0 0 3.333-3.333M8 10 4.667 6.667"
              />
            </svg>
          </div>

          {/* Thumbnail Image */}
          <img
            src={item.thumbnailUrl || '/placeholder.svg'}
            alt="Thumbnail"
            className="h-7 object-cover rounded border border-[var(--color-border)]"
          />
        </td>
      </div>

      {/* Trimmed Job ID */}
      <td className="px-4 py-2">
        {item.jobId.slice(0, 8)} {/* Trimmed job ID */}
      </td>

      {/* Date Processed */}
      <td className="px-4 py-2">
        {new Date(item.dateProcessed).toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })}
      </td>

      {/* Tool Used */}
      <td className="px-4 py-2">{item.tool}</td>

      {/* Tokens Spent */}
      <td className="px-4 py-2">
        {item.tokensSpent ? `${item.tokensSpent} tokens` : 'tokens'}
      </td>
    </tr>
  );
};

UsageHistoryRow.propTypes = {
  item: PropTypes.shape({
    jobId: PropTypes.string, // Make this not required to avoid errors
    dateProcessed: PropTypes.string.isRequired,
    tool: PropTypes.string.isRequired,
    tokensSpent: PropTypes.number.isRequired,
    thumbnailUrl: PropTypes.string, // Thumbnail URL for image
  }).isRequired,
};

export default UsageHistoryRow;
