// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ActionCardsGrid.css */

.scrollable-grid-container {
    position: relative;
  }
  
  .overflow-y-auto {
    scroll-behavior: smooth;
  }
  
  .gradient-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    pointer-events: none; /* Ensure the gradient does not block interactions */
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    opacity: 1;
    visibility: visible;
  }
  
  .hidden-gradient {
    opacity: 0;
    visibility: hidden;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/ActionCardsGrid.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;IACI,kBAAkB;EACpB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,0FAA0F;IAC1F,oBAAoB,EAAE,oDAAoD;IAC1E,iEAAiE;IACjE,UAAU;IACV,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,kBAAkB;EACpB","sourcesContent":["/* ActionCardsGrid.css */\n\n.scrollable-grid-container {\n    position: relative;\n  }\n  \n  .overflow-y-auto {\n    scroll-behavior: smooth;\n  }\n  \n  .gradient-overlay {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    height: 50px;\n    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%);\n    pointer-events: none; /* Ensure the gradient does not block interactions */\n    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;\n    opacity: 1;\n    visibility: visible;\n  }\n  \n  .hidden-gradient {\n    opacity: 0;\n    visibility: hidden;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
