// TooltipData.js

export const getPlanTooltipData = (planName) => {
  const data = {
    Standard: [
      {
        input: '480p (854×480)',
        details: [
          { images: 400, output: '920p (2x upscale)' },
          { images: 200, output: '1440p (3x upscale)' }
        ],
        displayString: 'Input of 480p (854×480) - 400 images to 920p, 200 images to 1440p'
      },
      {
        input: '720p (1280×720)',
        details: [
          { images: 200, output: '1440p (2x upscale)' },
          { images: 89, output: '2160p (3x upscale)' }
        ],
        displayString: 'Input of 720p (1280×720) - 200 images to 1440p, 89 images to 2160p'
      },
      {
        input: '1080p (1920x1080)',
        details: [
          { images: 89, output: '2160p (2x upscale)' },
          { images: 42, output: '3240p (3x upscale)' }
        ],
        displayString: 'Input of 1080p (1920x1080) - 89 images to 2160p, 42 images to 3240p'
      }
    ],
    Pro: [
      {
        input: '480p (854×480)',
        details: [
          { images: 1200, output: '920p (2x upscale)' },
          { images: 600, output: '1440p (3x upscale)' }
        ]
      },
      {
        input: '720p (1280×720)',
        details: [
          { images: 600, output: '1440p (2x upscale)' },
          { images: 267, output: '2160p (3x upscale)' }
        ]
      },
      {
        input: '1080p (1920x1080)',
        details: [
          { images: 267, output: '2160p (2x upscale)' },
          { images: 126, output: '3240p (3x upscale)' }
        ]
      }
    ],
    Studio: [
      {
        input: '480p (854×480)',
        details: [
          { images: 3500, output: '920p (2x upscale)' },
          { images: 1750, output: '1440p (3x upscale)' }
        ]
      },
      {
        input: '720p (1280×720)',
        details: [
          { images: 1750, output: '1440p (2x upscale)' },
          { images: 778, output: '2160p (3x upscale)' }
        ]
      },
      {
        input: '1080p (1920x1080)',
        details: [
          { images: 778, output: '2160p (2x upscale)' },
          { images: 368, output: '3240p (3x upscale)' }
        ]
      }
    ],
  };
  // Normalize planName to match the keys
  const normalizedPlanName = planName.replace(' Subscription', '');
  return data[normalizedPlanName] || [];
};

export const getTokenTooltipData = (tokenPack) => {
  const data = {
    'Starter Pack': [
      {
        input: '480p (854×480)',
        details: [
          { images: 75, output: '920p (2x upscale)' },
          { images: 38, output: '1440p (3x upscale)' }
        ]
      },
      {
        input: '720p (1280×720)',
        details: [
          { images: 38, output: '1440p (2x upscale)' },
          { images: 17, output: '2160p (3x upscale)' }
        ]
      },
      {
        input: '1080p (1920x1080)',
        details: [
          { images: 17, output: '2160p (2x upscale)' },
          { images: 8, output: '3240p (3x upscale)' }
        ]
      }
    ],
    'Creator Pack': [
      {
        input: '480p (854×480)',
        details: [
          { images: 225, output: '920p (2x upscale)' },
          { images: 113, output: '1440p (3x upscale)' }
        ]
      },
      {
        input: '720p (1280×720)',
        details: [
          { images: 113, output: '1440p (2x upscale)' },
          { images: 50, output: '2160p (3x upscale)' }
        ]
      },
      {
        input: '1080p (1920x1080)',
        details: [
          { images: 50, output: '2160p (2x upscale)' },
          { images: 24, output: '3240p (3x upscale)' }
        ]
      }
    ],
    'Studio Pack': [
      {
        input: '480p (854×480)',
        details: [
          { images: 900, output: '920p (2x upscale)' },
          { images: 450, output: '1440p (3x upscale)' }
        ]
      },
      {
        input: '720p (1280×720)',
        details: [
          { images: 450, output: '1440p (2x upscale)' },
          { images: 200, output: '2160p (3x upscale)' }
        ]
      },
      {
        input: '1080p (1920x1080)',
        details: [
          { images: 200, output: '2160p (2x upscale)' },
          { images: 95, output: '3240p (3x upscale)' }
        ]
      }
    ],
    'Production Pack': [
      {
        input: '480p (854×480)',
        details: [
          { images: 2700, output: '920p (2x upscale)' },
          { images: 1350, output: '1440p (3x upscale)' }
        ]
      },
      {
        input: '720p (1280×720)',
        details: [
          { images: 1350, output: '1440p (2x upscale)' },
          { images: 600, output: '2160p (3x upscale)' }
        ]
      },
      {
        input: '1080p (1920x1080)',
        details: [
          { images: 600, output: '2160p (2x upscale)' },
          { images: 284, output: '3240p (3x upscale)' }
        ]
      }
    ],
  };
  return data[tokenPack] || [];
};
