// src/components/ActionCardsGrid.js

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ActionCard from "./sharedCards/ActionCard";
import actionCards from "./ActionCardsData"; // Importing the action cards data
import { useActionCard } from "./ActionCardContext"; // Import the context hook
import useVisibleRows from "../hooks/useVisibleRows"; // Import the hook for visible rows
import debounce from "lodash.debounce";

import "../styles/ActionCardGridTransitions.css";
import "../styles/ActionCardsGrid.css";

const ActionCardsGrid = ({ firstName }) => {
  const navigate = useNavigate();
  const scrollableContainerRef = useRef(null);
  const { setSelectedCard } = useActionCard(); // Access the setSelectedCard from the context
  const [searchTerm, setSearchTerm] = useState("");
  const [showGradient, setShowGradient] = useState(false);

  // Use custom hook to calculate number of visible rows
  const visibleRows = useVisibleRows();

  const suggestionCard = {
    id: 5,
    title: "Suggest a Style",
    description:
      "Are we missing a style? Please help us by suggesting a style that you need.",
    buttonText: "Suggest on Discord",
    onButtonClick: () => window.open("https://discord.gg/UprGQaBMDY", "_blank"),
    newBadge: true,
    betaBadge: false,
    comingSoon: false,
    isSuggestionCard: true,
  };

  const filteredCards = actionCards.filter((card) =>
    card.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleScroll = () => {
    if (scrollableContainerRef.current) {
      const container = scrollableContainerRef.current;
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      const isAtBottom = scrollHeight - scrollTop - clientHeight < 5; // 5px buffer

      // Only show gradient if user is not searching and content is not at the bottom
      setShowGradient(searchTerm === "" && !isAtBottom);
    }
  };

  useEffect(() => {
    const container = scrollableContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    // Initial check for gradient visibility
    handleScroll();

    // Clean up event listener on unmount
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [visibleRows, searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value !== "") {
      setShowGradient(false);
    } else {
      handleScroll(); // Check if we need the gradient again when the search box is cleared
    }
  };

  return (
    <div className="max-w-7xl mx-auto relative">
      <div className="flex justify-between items-center text-15 mb-1">
        <p className="mr-[2px]">Hey {firstName}, let's channel some Vudoo?</p>

        <div className="h-10 flex items-center gap-2 mr-[2px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-white/50 shrink-0"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
          <input
            type="text"
            placeholder="Search styles"
            value={searchTerm}
            onChange={handleSearchChange}
            className="bg-transparent text-white/50 text-15 font-normal leading-tight focus:outline-none w-[88px] truncate"
          />
        </div>
      </div>

      <div className="scrollable-grid-container overflow-hidden relative">
        <div
          ref={scrollableContainerRef}
          className="overflow-y-auto pb-10"
          style={{ maxHeight: `calc(${visibleRows} * 480px + ${(visibleRows - 1) * 1}rem)` }}
        >
          <TransitionGroup className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 relative">
            {filteredCards.map((card) => (
              <CSSTransition key={card.id} timeout={300} classNames="card">
                <div className="w-full aspect-[332/415] max-w-[332px] mx-auto">
                  <ActionCard
                    {...card}
                    onButtonClick={() => {
                      setSelectedCard(card);
                      navigate(`/upload?type=${card.type}&endpoint=${card.endpoint}`);
                    }}
                    className="w-full h-full"
                  />
                </div>
              </CSSTransition>
            ))}
            <CSSTransition key={suggestionCard.id} timeout={300} classNames="card">
              <div className="w-full aspect-[332/415] max-w-[332px] mx-auto">
                <ActionCard {...suggestionCard} className="w-full h-full" />
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
        {showGradient && <div className="gradient-overlay visible" aria-hidden="true"></div>}
      </div>
    </div>
  );
};

export default ActionCardsGrid;
